import _ from 'lodash';

import isDirectBillingsPayment from '../../../../../pages/payday/components/payments-uploader/utils/is-direct-billings-payment';
import convertPayment from '../../../../../shared/payment-files/convert-payment';
import findMatchingProvider from '../../../../../shared/payment-files/find-matching-provider';

export default (providerList, paymentData) => {
  const importTotals = {
    eftTotals: {},
    cashTotals: {},
    chequeTotals: {},
    depositAllocations: [],
    providerList: _.map(providerList, (provider) => {
      return _.assign({}, provider, {
        medicareTotals: {},
        dvaTotals: {},
      });
    }),
  };

  _.forEach(paymentData, (payment) => {
    if (!_.isNil(payment.Location) && !_.isEmpty(payment.Location)) {
      const convertedPayment = convertPayment(payment);

      const provider = findMatchingProvider(
        importTotals.providerList,
        convertedPayment
      );

      const skipPayment = isDirectBillingsPayment({
        provider,
        transactionDate: convertedPayment['Transaction Date'],
      });

      if (skipPayment) {
        return;
      }

      /**
       * Accumulate medicare totals
       */
      if (
        _.isEqual(
          _.toLower(convertedPayment['Payment Method']),
          'direct credit'
        ) &&
        _.isEqual(_.toLower(convertedPayment['Account Type']), 'medicare')
      ) {
        const medicareTotal = _.get(
          provider.medicareTotals,
          `${convertedPayment['Transaction Date']}.${convertedPayment['Payment ID']}`,
          0
        );

        _.setWith(
          provider.medicareTotals,
          `${convertedPayment['Transaction Date']}.${convertedPayment['Payment ID']}`,
          medicareTotal + convertedPayment.Payment,
          Object
        );
      }

      /**
       * Accumulate dva totals
       */
      if (
        _.isEqual(
          _.toLower(convertedPayment['Payment Method']),
          'direct credit'
        ) &&
        _.isEqual(_.toLower(convertedPayment['Account Type']), 'dva')
      ) {
        const medicareTotal = _.get(
          provider.dvaTotals,
          `${convertedPayment['Transaction Date']}.${convertedPayment['Payment ID']}`,
          0
        );

        _.setWith(
          provider.dvaTotals,
          `${convertedPayment['Transaction Date']}.${convertedPayment['Payment ID']}`,
          medicareTotal + convertedPayment.Payment,
          Object
        );
      }

      /**
       * Accumulate eft / tyro totals
       */
      if (
        _.includes(
          ['eft', 'credit card'],
          _.toLower(convertedPayment['Payment Method'])
        )
      ) {
        const eftTotal = _.get(
          importTotals.eftTotals,
          convertedPayment['Transaction Date'],
          0
        );

        _.set(
          importTotals.eftTotals,
          convertedPayment['Transaction Date'],
          eftTotal + convertedPayment.Payment
        );
      }

      /**
       * Accumulate cash per day
       */
      if (_.includes(['cash'], _.toLower(convertedPayment['Payment Method']))) {
        const cashTotal = _.get(
          importTotals.cashTotals,
          convertedPayment['Transaction Date'],
          {}
        );

        const cashAmount = _.get(cashTotal, 'amount', 0);
        const gstAmount = _.get(cashTotal, 'gst', 0);
        const newCashTotal = {
          amount: cashAmount + convertedPayment.Payment,
          gst: gstAmount + convertedPayment.GST,
        };

        _.set(
          importTotals.cashTotals,
          convertedPayment['Transaction Date'],
          newCashTotal
        );
      }

      /**
       * Accumulate cheque per day
       */
      if (
        _.includes(['cheque'], _.toLower(convertedPayment['Payment Method']))
      ) {
        const chequeTotal = _.get(
          importTotals.chequeTotals,
          convertedPayment['Transaction Date'],
          {}
        );

        const chequeAmount = _.get(chequeTotal, 'amount', 0);
        const gstAmount = _.get(chequeTotal, 'gst', 0);
        const newChequeTotal = {
          amount: chequeAmount + convertedPayment.Payment,
          gst: gstAmount + convertedPayment.GST,
        };

        _.set(
          importTotals.chequeTotals,
          convertedPayment['Transaction Date'],
          newChequeTotal
        );
      }

      /**
       * Accumulate deposit allocations
       */
      if (
        _.isEqual(
          _.toLower(convertedPayment['Payment Method']),
          'deposit allocation'
        )
      ) {
        importTotals.depositAllocations.push({
          accountType: convertedPayment['Account Type'],
          billedTo: convertedPayment['Billed To'],
          invoiceNo: convertedPayment['Invoice No.'],
          transactionDate: convertedPayment['Transaction Date'],
          gst: convertedPayment.GST,
          amount: convertedPayment.Payment,
          serviceId: convertedPayment['Service ID'],
          provider: convertedPayment.Provider,
          providerId: provider.id,
        });
      }
    }
  });

  /**
   * Second parse of payments to classify Deposit Allocations
   */
  _.forEach(paymentData, (payment) => {
    const paymentMethod = _.toLower(payment['Payment Method']);
    if (
      !_.isEmpty(paymentMethod) &&
      !_.isEqual(paymentMethod, 'deposit allocation')
    ) {
      let matchedPayment = _.find(
        importTotals.depositAllocations,
        (deposit) => {
          return (
            (_.get(deposit, 'serviceId') === payment['Service ID'] ||
              _.get(deposit, 'invoiceNo') === payment['Invoice No.']) &&
            !_.includes(_.get(deposit, 'paymentMethod'), '-') &&
            _.get(deposit, 'transactionDate') === payment['Transaction Date']
          );
        }
      );

      if (matchedPayment) {
        _.set(
          matchedPayment,
          'paymentMethod',
          `Deposit Allocation - ${payment['Payment Method']}`
        );
      }
    }
  });

  // Filter out any direct credit deposit allocations
  importTotals.depositAllocations = _.filter(
    importTotals.depositAllocations,
    (deposit) => {
      return !_.includes(_.toLower(deposit.paymentMethod), 'direct credit');
    }
  );

  // Filter out any eft deposit allocations that relate to independent doctor direct billings
  importTotals.depositAllocations = _.filter(
    importTotals.depositAllocations,
    (deposit) => {
      const provider = findMatchingProvider(
        importTotals.providerList,
        deposit,
        true
      );

      const skipPayment = isDirectBillingsPayment({
        accountType: deposit.accountType,
        billedTo: deposit.billedTo,
        provider,
        paymentMethod: deposit.paymentMethod,
        transactionDate: deposit.transactionDate,
      });

      return !skipPayment;
    }
  );

  return importTotals;
};
