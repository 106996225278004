import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';

import { formatNumber } from '../../../../../../utils';
import GstLines from '../../../gst-lines';
import styles from './styles.module.sass';

const ProviderPaymentsRow = (props) => {
  const [showGst, setShowGst] = useState(false);
  const { provider } = props;
  let iconName = 'plus';
  if (showGst) {
    iconName = 'minus';
  }

  const hasGstItems = provider.gstLines.length > 0;
  return (
    <>
      <Table.Row key={provider.name}>
        <Table.Cell className={classNames({ [styles.withGst]: hasGstItems })}>
          {provider.name}
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          className={classNames({ [styles.withGst]: hasGstItems })}
        >
          {formatNumber(provider.paymentTotal)}
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          className={classNames({ [styles.withGst]: hasGstItems })}
        >
          {formatNumber(provider.paymentTotal - provider.directBillingsTotal)}
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          className={classNames({ [styles.withGst]: hasGstItems })}
        >
          {formatNumber(provider.grossGst)}
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          className={classNames({ [styles.withGst]: hasGstItems })}
        >
          {formatNumber(provider.gstTotal)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {hasGstItems && (
            <Button
              onClick={() => setShowGst(!showGst)}
              size="mini"
              icon
              basic
              color="blue"
            >
              <Icon name={iconName} />
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
      {provider.gstLines.length > 0 && showGst && (
        <Table.Row>
          <Table.Cell colSpan="5">
            <GstLines gstLines={provider.gstLines} />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default ProviderPaymentsRow;
