import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { Icon, Modal, Popup, Segment } from 'semantic-ui-react';

import { formatNumber } from '../../../../../utils';
import GstLines from '../../gst-lines';
import styles from './styles.module.sass';

const CalculatorStats = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { payday, gstLines } = props;
  const hasGstLines = !_.isEmpty(gstLines);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {payday.serviceFeeTotal !== 0 && (
        <>
          <Segment textAlign="left">
            <h4>
              <span className={styles.registrarBillings}>
                Registrar Billings
              </span>
              <Popup
                content="This figure is calculated as actual registrar PMS billings plus any registrar online script fees less any registrar fees that contain gst"
                mouseEnterDelay={500}
                mouseLeaveDelay={500}
                on="hover"
                trigger={<Icon link name="question circle outline" />}
              />
            </h4>
            <span className={styles.largeDollars}>
              {formatNumber(
                payday.registrarFeeTotal + payday.registrarOnlineScriptsTotal
              )}
            </span>
            <h4>Fee Total</h4>
            <span className={styles.largeDollars}>
              {formatNumber(payday.serviceFeeTotal)}
            </span>
            <h4>Practice Charges (incl gst)</h4>
            <div
              className={classNames(styles.gstWrapper, {
                [styles.withGst]: hasGstLines,
              })}
            >
              <span className={styles.largeDollars}>
                {formatNumber(payday.practiceChargesInclGST)}
              </span>
              {hasGstLines && (
                <Icon
                  data-testid="gstlines-icon"
                  color="blue"
                  size="large"
                  name="plus square outline"
                  onClick={openModal}
                />
              )}
            </div>
            <h4>Practice Charges (gst free)</h4>
            <span className={styles.largeDollars}>
              {formatNumber(payday.practiceChargesGSTFree)}
            </span>
            <h4>Online Scripts Total</h4>
            <span className={styles.largeDollars}>
              {formatNumber(payday.onlineScriptsTotal)}
            </span>
            {payday.onlineScriptsPeriod && (
              <>
                <br />
                <span>({payday.onlineScriptsPeriod})</span>
              </>
            )}
            <h4>Procedure Fees</h4>
            <span className={styles.largeDollars}>
              {formatNumber(payday.procedureFees)}
            </span>
            <h4>Procedure Adjustments</h4>
            <span className={styles.largeDollars}>
              {formatNumber(payday.procedureAdjustments)}
            </span>
            <h4>Grand Total</h4>
            <span className={styles.largeDollars}>
              {formatNumber(payday.grandTotal + payday.procedureAdjustments)}
            </span>
            <h4>Held Total</h4>
            <span className={styles.largeDollars}>
              {formatNumber(
                payday.grandTotal -
                  payday.directBillingsTotal +
                  payday.procedureAdjustments
              )}
            </span>
          </Segment>
          <Modal
            dimmer="blurring"
            closeIcon
            data-testid="gstlines-modal"
            size={'large'}
            open={isModalOpen}
            onClose={closeModal}
          >
            <Modal.Content>
              <GstLines gstLines={gstLines} />
            </Modal.Content>
          </Modal>
        </>
      )}
    </>
  );
};

export default CalculatorStats;
