import Constants from '../../constants';
import paydayApi from '../payday';
import paymentsApi from '../payments';
import customFeeItemsApi from '../custom-fee-items';
import providerApi from '../provider';

const setupCalls = (payload) => {
  const promises = [];

  switch (payload.type) {
    case Constants.LOAD.PROVIDER_LIST:
    case Constants.LOAD.PAYDAY:
      promises.push(providerApi.getProviders());
      break;
    case Constants.LOAD.PAYDAY_HISTORY:
      promises.push(paydayApi.getPaydayHistory(payload.options));
      break;
    case Constants.LOAD.PAYDAY_DETAIL:
      promises.push(paydayApi.getPaydayDetail(payload.options.paydayId));
      break;
    case Constants.LOAD.PAYMENT_GROUPS:
      promises.push(paymentsApi.getPaymentGroups(payload.options));
      break;
    case Constants.LOAD.PRACTICE_FEE_DESCRIPTIONS:
      promises.push(customFeeItemsApi.getCustomFeeItems());
      break;
    default:
    // do nothing
  }

  return promises;
};

export default async (payload) => {
  const promises = setupCalls(payload);
  const response = await Promise.all(promises);
  return response[0].data;
};
