import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import Constants from '../../../../constants';
import { Context } from '../../../../store';
import CalculatorResetButton from '../common/calculator-reset-button';
import CalculatorStats from '../common/calculator-stats';
import ProviderInvoiceList from '../common/provider-invoice-list';
import CalculatorButton from './calculator-button';
import extractGstLines from './extract-gst-lines';

const InvoiceCalculator = () => {
  const { store, dispatch } = useContext(Context);

  const performCalculation = () => {
    dispatch({ type: Constants.ACTIONS.CALCULATE_INVOICES });
  };

  const disabled = store.payday.stepNo > 2;
  const practiceGstLines = extractGstLines(store.payday.providerList, true);
  const providerGstLines = extractGstLines(store.payday.providerList, false);

  return (
    <Grid.Row>
      <Grid.Column width={4}>
        <CalculatorButton
          disabled={disabled}
          performCalculation={performCalculation}
        />
        {store.payday.stepNo === 2 && <CalculatorResetButton />}
        <CalculatorStats payday={store.payday} gstLines={practiceGstLines} />
      </Grid.Column>
      <Grid.Column width={12}>
        <ProviderInvoiceList
          payday={store.payday}
          providerList={store.payday.providerList}
          providerGstLines={providerGstLines}
          isDisplayOnly={false}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default InvoiceCalculator;
