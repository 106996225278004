import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Icon, Image, Menu } from 'semantic-ui-react';

import logo from '../../../assets/images/logo-white.png';
import Constants from '../../../constants';
import { Context } from '../../../store';
import styles from './styles.module.sass';

const SideNav = (props) => {
  const path = _.get(props, 'match.path');

  const { dispatch } = useContext(Context);
  const [activeItem, setActiveItem] = useState(path);

  const handleItemClick = (_e, opts) => {
    const newPath = _.get(opts, 'path');
    setActiveItem(newPath);
    props.history.push(newPath);
  };

  const handleLogout = () => {
    dispatch({ type: Constants.ACTIONS.LOGOUT });
  };

  return (
    <div className={styles.navWrapper}>
      <div>
        <Header as="h2">
          <Image spaced="right" verticalAlign="middle" size="mini" src={logo} />
          <span className={styles.navHeader}>Stepping Stones</span>
        </Header>
      </div>
      <Menu secondary vertical>
        <Menu.Item
          path={Constants.URLS.DASHBOARD}
          active={activeItem === Constants.URLS.DASHBOARD}
          onClick={handleItemClick}
        >
          <div className={styles.navItem}>
            <Icon name="home" />
            Home
          </div>
        </Menu.Item>
        <Menu.Item
          path={Constants.URLS.PROVIDER_LIST}
          active={activeItem === Constants.URLS.PROVIDER_LIST}
          onClick={handleItemClick}
        >
          <div className={styles.navItem}>
            <Icon name="users" />
            Providers
          </div>
        </Menu.Item>
        <Menu.Item
          path={Constants.URLS.PAYDAY}
          active={activeItem === Constants.URLS.PAYDAY}
          onClick={handleItemClick}
        >
          <div className={styles.navItem}>
            <Icon name="money bill alternate outline" />
            New Payday
          </div>
        </Menu.Item>
        <Menu.Item
          path={Constants.URLS.PAYDAY_HISTORY}
          active={activeItem === Constants.URLS.PAYDAY_HISTORY}
          onClick={handleItemClick}
        >
          <div className={styles.navItem}>
            <Icon name="archive" />
            Payday History
          </div>
        </Menu.Item>
        <Menu.Item
          path={Constants.URLS.RECONCILE_PAYMENTS}
          active={activeItem === Constants.URLS.RECONCILE_PAYMENTS}
          onClick={handleItemClick}
        >
          <div className={styles.navItem}>
            <Icon name="calendar check outline" />
            Reconcile Payments
          </div>
        </Menu.Item>
        <Menu.Item
          path="logout"
          active={activeItem === 'logout'}
          onClick={handleLogout}
        >
          <div className={styles.navItem}>
            <Icon name="sign-out" />
            Logout
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(SideNav);
