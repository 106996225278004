import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';

import Constants from './constants';
import Dashboard from './pages/dashboard';
import FourOhFour from './pages/fourohfour';
import Home from './pages/home';
import Login from './pages/login';
import Payday from './pages/payday';
import PaydayHistory from './pages/payday/history';
import PaydayHistoryDetail from './pages/payday/history-detail';
import Providers from './pages/providers';
import ReconcilePayments from './pages/reconcile-payments';
import { Context, reducer } from './store';
import initialState from './store/initial-state';

const PrivateRoute = ({ component: Component, exact, path }) => {
  const { store } = useContext(Context);
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        store.isAuthenticated === true ? (
          <Dashboard>
            <Component props={props} />
          </Dashboard>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function App() {
  const token = sessionStorage.getItem('ss-token');
  let isAuthenticated = false;
  if (token) {
    isAuthenticated = true;
  }

  const [store, dispatch] = useImmerReducer(reducer, {
    ...initialState,
    isAuthenticated,
  });

  return (
    <Context.Provider value={{ store, dispatch }}>
      <div>
        <Switch>
          <Route exact path={Constants.URLS.ROOT} component={Login} />
          <PrivateRoute
            exact
            path={Constants.URLS.DASHBOARD}
            component={Home}
          />
          <PrivateRoute
            exact
            path={Constants.URLS.PROVIDER_LIST}
            component={Providers}
          />
          <PrivateRoute exact path={Constants.URLS.PAYDAY} component={Payday} />
          <PrivateRoute
            exact
            path={Constants.URLS.PAYDAY_HISTORY}
            component={PaydayHistory}
          />
          <PrivateRoute
            exact
            path={Constants.URLS.RECONCILE_PAYMENTS}
            component={ReconcilePayments}
          />
          <PrivateRoute
            exact
            path={`${Constants.URLS.PAYDAY_HISTORY}/:id`}
            component={PaydayHistoryDetail}
          />
          <Route component={FourOhFour} />
        </Switch>
      </div>
    </Context.Provider>
  );
}

export default App;
