import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Dropdown, Grid, Message } from 'semantic-ui-react';

import constants from '../../../../../constants';

const FinYearFilter = ({ applyFilter, finYears }) => {
  const reversedYears = _.reverse(_.clone(finYears));
  const [selectedYears, setSelectedYears] = useState(
    _.take(reversedYears, constants.CHARTS.FIN_YEAR_LIMIT)
  );
  const [showError, setShowError] = useState(false);

  const options = _.map(reversedYears, (finYear, index) => {
    return { key: finYear, text: finYear, value: finYear };
  });

  const handleChange = (e, { value }) => {
    const selected = _.isEmpty(value)
      ? _.take(reversedYears, constants.CHARTS.FIN_YEAR_LIMIT)
      : value;
    setSelectedYears(selected.sort());
    setShowError(_.size(selected) > constants.CHARTS.FIN_YEAR_LIMIT);
  };

  const handleOnClick = () => {
    applyFilter(selectedYears);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Dropdown
            placeholder="Financial Year"
            fluid
            clearable
            multiple
            selection
            error={showError}
            closeOnChange
            options={options}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Button
            basic
            disabled={showError}
            onClick={handleOnClick}
            color="blue"
            content="Apply Filter"
            icon="filter"
            labelPosition="left"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {showError && (
            <Message negative>
              Please select a maximum of {constants.CHARTS.FIN_YEAR_LIMIT}{' '}
              financial years.
            </Message>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FinYearFilter;
