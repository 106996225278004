import _ from 'lodash';
import React from 'react';
import { Input } from 'semantic-ui-react';

function OnlineScriptInput(props) {
  const handleOnChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <>
      <Input
        onChange={handleOnChange}
        disabled={_.get(props, 'disabled')}
        fluid
      />
    </>
  );
}

export default OnlineScriptInput;
