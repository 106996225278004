import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';

import PaymentsAPI from '../../../../api/payments';
import { formatNumber } from '../../../../utils';
import ReconciliationActions from '../../context';
import { MODE_RECONCILE } from '../reconciliation-modes';
import styles from './styles.module.sass';

const SelectedPaymentsAggregator = ({
  selectedPayments,
  mode = MODE_RECONCILE,
}) => {
  const actions = useContext(ReconciliationActions);
  const [isReconciling, setIsReconciling] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const paymentCount = _.size(selectedPayments);
  const paymentTotal = _.reduce(
    selectedPayments,
    (sum, payment) => {
      return sum + payment.amount;
    },
    0
  );

  const clearSelection = () => {
    actions.clearSelectedPayments();
  };

  const isReconcileMode = mode === MODE_RECONCILE;
  const buttonColor = isReconcileMode ? 'blue' : 'orange';

  const paymentsReconciled = async () => {
    setIsReconciling(true);
    const paymentIds = _.map(selectedPayments, 'id');
    await PaymentsAPI.reconcilePaymentGroups({
      paymentGroupIds: paymentIds,
      reconcile: isReconcileMode,
    });
    // TODO add try / catch to handle error
    setIsReconciling(false);
    actions.paymentsReconciled(paymentIds);
  };

  const deletePayments = async () => {
    setIsDeleting(true);
    const paymentIds = _.map(selectedPayments, 'id');
    await PaymentsAPI.deletePaymentGroups({
      paymentGroupIds: paymentIds,
    });
    // TODO add try / catch to handle error
    setIsDeleting(false);
    setIsModalOpen(false);
    actions.paymentsReconciled(paymentIds);
  };

  return (
    <Message info={isReconcileMode} warning={!isReconcileMode}>
      <div className={styles.infoRow}>
        <div className={styles.amount}>{`Row count: ${paymentCount}`}</div>
        <div className={styles.amount}>{`Payment total: ${formatNumber(
          paymentTotal
        )}`}</div>
        <div className={styles.wrapper}>
          <div className={styles.button}>
            <Button
              disabled={isReconciling}
              onClick={clearSelection}
              basic
              icon
              color={buttonColor}
              labelPosition="left"
            >
              <Icon name="redo" />
              Clear
            </Button>
          </div>
          <div className={styles.button}>
            <Button
              disabled={isReconciling}
              onClick={paymentsReconciled}
              loading={isReconciling}
              basic
              icon
              color={buttonColor}
              labelPosition="left"
            >
              <Icon name="check square outline" />
              {isReconcileMode ? 'Reconcile' : 'Unreconcile'}
            </Button>
          </div>
          {isReconcileMode && (
            <div className={styles.button}>
              <Button
                disabled={isDeleting}
                onClick={() => setIsModalOpen(true)}
                loading={isDeleting}
                basic
                icon
                color="red"
                labelPosition="left"
              >
                <Icon name="check square outline" />
                Delete
              </Button>
            </div>
          )}
          <Modal
            dimmer="blurring"
            size="mini"
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <Modal.Header>Delete Payments</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to delete the selected payments</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={() => setIsModalOpen(false)}>
                No
              </Button>
              <Button positive onClick={() => deletePayments()}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    </Message>
  );
};

export default SelectedPaymentsAggregator;
