import _ from 'lodash';

import getRegistrarDoctors from '../../../shared/get-registrar-doctors';

export default (providerList) => {
  const registrarDoctors = getRegistrarDoctors(providerList);

  let registrarBilledTotal = 0;
  let registrarScriptTotal = 0;
  let registrarFeesWithGst = 0;

  _.forEach(registrarDoctors, (provider) => {
    registrarBilledTotal += provider.paymentTotal;
    registrarScriptTotal += provider.onlineScripts;
    registrarFeesWithGst += provider.grossGst;
  });

  return {
    registrarBilledTotal,
    registrarScriptTotal,
    registrarFeesWithGst,
  };
};
