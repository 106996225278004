import _ from 'lodash';
import React from 'react';
import { Button } from 'semantic-ui-react';

function AbaGeneratorButton(props) {
  return (
    <>
      <Button
        basic
        fluid
        onClick={_.get(props, 'performGeneration')}
        color="blue"
        content="Generate ABA File"
        icon="download"
        labelPosition="left"
        disabled={_.get(props, 'isGenerating')}
        loading={_.get(props, 'isGenerating')}
      />
    </>
  );
}

export default AbaGeneratorButton;
