import _ from 'lodash';

import Constants from '../../constants';
import calculateInvoices from './calculate-invoices';

export default (draft, action) => {
  switch (action.type) {
    case Constants.ACTIONS.CALCULATE_INVOICES:
      calculateInvoices(draft);

      draft.payday.stepNo = 3;
      draft.payday.allowOnlineScriptEntry = false;
      break;

    case Constants.ACTIONS.ADD_ONLINE_SCRIPT: {
      const value = Number(action.payload.value);
      const filtered = _.filter(draft.payday.providerList, {
        name: action.payload.providerName,
      });

      const provider = filtered[0];
      provider.onlineScripts = value;

      calculateInvoices(draft);
      break;
    }

    case Constants.ACTIONS.ADJUST_PROCEDURE_FEES: {
      const value = Number(action.payload.value);
      const filtered = _.filter(draft.payday.providerList, {
        name: action.payload.providerName,
      });

      const provider = filtered[0];
      provider.procedureFees = value;

      calculateInvoices(draft);
      break;
    }

    default:
  }
};
