import _ from 'lodash';

import { roundTo2DP } from '../../../../../../utils';

export default (amount) => {
  let newAmount = amount;
  if (amount && _.startsWith(amount, '(')) {
    newAmount = `-${amount.substring(1, amount.length - 1)}`;
  }
  return roundTo2DP(parseFloat(_.replace(newAmount, ',', '')));
};
