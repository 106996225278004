import React from 'react';
import { Button } from 'semantic-ui-react';

const CalculatorButton = (props) => {
  const { performCalculation, disabled } = props;
  return (
    <>
      <Button
        basic
        fluid
        onClick={performCalculation}
        color="blue"
        content="2. Calculate Invoices"
        icon="calculator"
        labelPosition="left"
        disabled={disabled}
      />
    </>
  );
};

export default CalculatorButton;
