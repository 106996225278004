import _ from 'lodash';
import React from 'react';
import { Button } from 'semantic-ui-react';

const UploaderButton = (props) => {
  const {
    disabled,
    handleFileChosen,
    handleFileSelect,
    fileInputRef,
    isLoading,
  } = props;

  return (
    <>
      <Button
        basic
        fluid
        onClick={handleFileSelect}
        color="blue"
        content="1. Upload Payments File"
        icon="file alternate outline"
        labelPosition="left"
        disabled={isLoading || disabled}
        loading={isLoading}
      />
      <input
        ref={fileInputRef}
        hidden
        type="file"
        onChange={handleFileChosen}
        onClick={(event) => {
          _.set(event, 'target.value', null);
        }}
        accept=".csv"
      />
    </>
  );
};

export default UploaderButton;
