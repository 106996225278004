import React, { useContext } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

import Constants from '../../../constants';
import { Context } from '../../../store';

const ErrorModal = () => {
  const { store, dispatch } = useContext(Context);

  const handleClose = () => {
    dispatch({ type: Constants.ACTIONS.CLEAR_API_ERROR });
  };

  const handleLogout = () => {
    dispatch({ type: Constants.ACTIONS.LOGOUT });
  };

  let authError = false;
  let errorMessage =
    'There was a problem processing your request - please try again later.';

  switch (store.apiStatusCode) {
    case 401:
      authError = true;
      errorMessage =
        'Your session has expired. Please login again to continue.';
      break;

    default:
  }

  let buttonText = 'OK';
  let closeFunction = handleClose;
  if (authError) {
    closeFunction = handleLogout;
    buttonText = 'Logout';
  }

  return (
    <Modal open onClose={closeFunction} size="small">
      <Header as="h1" color="red" icon="thumbs down" content="Error!" />
      <Modal.Content>
        <h3 data-testid="errorMessage">{errorMessage}</h3>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={closeFunction} inverted>
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorModal;
