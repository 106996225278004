import fileDownload from 'js-file-download';
import _ from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';

import Constants from '../../../../../../constants';
import { formatNumber } from '../../../../../../utils';
import getPayableIndependentDoctors from '../../../../common/get-payable-independent-doctors';
import createInvoiceLinesForProvider from './create-invoice-lines-for-provider';

const invoiceExport = {
  invoiceNumberSuffix: '',
  exportedInvoiceCount: 0,
  exportedInvoiceFeeTotal: 0,
  exportedInvoiceArray: [],
  billingPeriodStart: new Date(),
  billingPeriodEnd: '',
};

export default (paydayDetail) => {
  return new Promise((resolve) => {
    const independentDoctors = getPayableIndependentDoctors(
      paydayDetail.providerList
    );

    let invoiceArray = [];
    let invoiceCount = 0;
    let invoiceFeeTotal = 0;
    const periodStart = moment(
      paydayDetail.billingStartDate,
      Constants.DATE_FORMAT_YEAR_FIRST
    );
    const periodStartStr = periodStart.format(Constants.DATE_FORMAT);
    const periodEnd = periodStart.add(13, 'days');
    const periodEndStr = periodEnd.format(Constants.DATE_FORMAT);
    const dueDateStr = periodEnd
      .clone()
      .add(6, 'days')
      .format(Constants.DATE_FORMAT);
    const suffixNumber = periodEnd.format(Constants.DATE_FORMAT_COMPACT);
    _.forEach(independentDoctors, (provider) => {
      invoiceCount += 1;
      invoiceFeeTotal += provider.grossServiceFee;
      invoiceArray = _.concat(
        invoiceArray,
        createInvoiceLinesForProvider({
          dueDateStr,
          onlineScriptsPeriod: paydayDetail.onlineScriptsPeriod,
          periodStartStr,
          periodEndStr,
          provider,
          suffixNumber,
        })
      );
    });

    invoiceExport.exportedInvoiceCount = invoiceCount;
    invoiceExport.exportedInvoiceFeeTotal = formatNumber(invoiceFeeTotal);
    invoiceExport.exportedInvoiceArray = invoiceArray;
    invoiceExport.billingPeriodEnd = periodEnd.format(
      Constants.DATE_FORMAT_YEAR_FIRST
    );

    const invoiceExportFile = Papa.unparse(invoiceExport.exportedInvoiceArray);
    const fileName = `invoices-${suffixNumber}.csv`;
    fileDownload(invoiceExportFile, fileName);

    resolve();
  });
};
