import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';

import { Context } from '../../../../../../store';
import { formatNumber } from '../../../../../../utils';
import styles from './styles.module.sass';

const UploaderStats = () => {
  const { store } = useContext(Context);

  return (
    <>
      {store.payday.grandTotal !== 0 && (
        <Segment textAlign="left">
          <h4>Grand Total</h4>
          <span className={styles.largeDollars}>
            {formatNumber(store.payday.grandTotal)}
          </span>
          <h4>Procedure Fees</h4>
          <span className={styles.largeDollars}>
            {formatNumber(store.payday.procedureFees)}
          </span>
        </Segment>
      )}
    </>
  );
};

export default UploaderStats;
