import axios from 'axios';

import Constants from '../../constants';

export const login = async (input) => {
  try {
    const response = await axios.post(
      `${Constants.API_BASE}/user/login`,
      input
    );
    sessionStorage.setItem('ss-token', response.data.jwt);
    return {
      ok: true,
    };
  } catch (error) {
    let errorMessage =
      'There was a problem logging you in. Please try again later.';
    if (error.response.status === 401) {
      errorMessage = 'Invalid username or password';
    }
    return {
      ok: false,
      errorMessage,
    };
  }
};

export const logout = () => {
  sessionStorage.removeItem('ss-token');
};
