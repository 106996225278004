import axios from 'axios';

import Constants from '../../constants';
import { getJWTHeader } from '../../utils';

const PaymentsAPI = {
  getPaymentGroups: (options) => {
    const authHeader = getJWTHeader();
    return axios.post(
      `${Constants.API_BASE}/payment-groups`,
      options.criteria,
      authHeader
    );
  },

  finalisePaymentImport: async (payload) => {
    const authHeader = getJWTHeader();
    const response = await axios.post(
      `${Constants.API_BASE}/payment-groups/create`,
      payload,
      authHeader
    );
    return response;
  },

  reconcilePaymentGroups: (payload) => {
    const authHeader = getJWTHeader();
    return axios.post(
      `${Constants.API_BASE}/payment-groups/reconcile`,
      payload,
      authHeader
    );
  },

  deletePaymentGroups: (payload) => {
    const authHeader = getJWTHeader();
    return axios.post(
      `${Constants.API_BASE}/payment-groups/delete`,
      payload,
      authHeader
    );
  },
};

export default PaymentsAPI;
