import _ from 'lodash';

import getIndependentDoctors from '../../../../shared/get-independent-doctors';

export default (providerList) =>
  _.filter(getIndependentDoctors(providerList), (provider) => {
    // A provider is only a payable provider if they have billed something
    return (
      provider.paymentTotal +
        provider.onlineScripts +
        provider.procedureFees !==
      0
    );
  });
