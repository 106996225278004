import React from 'react';

import Constants from '../constants';
import authReducer from '../reducers/auth-reducer';
import invoiceCalculatorReducer from '../reducers/invoice-calculator-reducer';
import paydayReducer from '../reducers/paydayReducer';
import paymentsUploaderReducer from '../reducers/paymentsUploaderReducer';

const reducer = (draft, action) => {
  authReducer(draft, action);
  paymentsUploaderReducer(draft, action);
  invoiceCalculatorReducer(draft, action);
  paydayReducer(draft, action);

  switch (action.type) {
    case Constants.ACTIONS.API_ERROR:
      draft.apiError = true;
      draft.apiStatusCode = action.payload.status;
      break;

    case Constants.ACTIONS.CLEAR_API_ERROR:
      draft.apiError = false;
      draft.apiStatusCode = '';
      break;

    default:
  }
};

const Context = React.createContext();

export { Context, reducer };
