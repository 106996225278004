import _ from 'lodash';

import convertAmount from '../../../pages/payday/components/payments-uploader/utils/convert-amount';

export default (payment) => {
  const convertedData = _.assign({}, payment);
  convertedData.GST = convertAmount(payment.GST);
  convertedData.Payment = convertAmount(payment.Payment);
  convertedData.Deposit = convertAmount(payment.Deposit);

  return convertedData;
};
