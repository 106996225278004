import _ from 'lodash';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { formatNumber } from '../../../../../utils';

const chartColours = [
  '#54478C',
  '#2C699A',
  '#048BA8',
  '#0DB39E',
  '#16DB93',
  '#83E377',
  '#B9E769',
  '#EFEA5A',
  '#F1C453',
  '#F29E4C',
];

const FortnightlyBillingsChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={_.get(data, 'data')}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="periodIndex" />
        <YAxis />
        <Tooltip
          formatter={(value) => {
            return `$${formatNumber(value)}`;
          }}
        />
        <Legend />
        {_.map(_.get(data, 'finYears'), (finYear, index) => {
          return (
            <Line
              key={finYear}
              name={finYear}
              dataKey={finYear}
              type="monotone"
              stroke={chartColours[index]}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default FortnightlyBillingsChart;
