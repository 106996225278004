import _ from 'lodash';
import moment from 'moment';

import Constants from '../../../../../../constants';

// This function used to do a lot more when some provider payments were not being sent directly to the
// provider. Now, essentially, all payments to the provider should be direct. This function just
// returns false for anything billed to the practice, or if the provider does not have direct billings
// (which really just covers registrars).
const isDirectBillingsPayment = ({ provider, transactionDate }) => {
  if (_.isEmpty(provider.directBillingsStartDate)) {
    return false;
  }

  const directBillingsStartDate = moment(
    provider.directBillingsStartDate,
    Constants.DATE_FORMAT_YEAR_FIRST
  );
  const paymentDate = moment(transactionDate, Constants.DATE_FORMAT);

  if (paymentDate.isBefore(directBillingsStartDate)) {
    return false;
  }

  return true;
};

export default isDirectBillingsPayment;
