import _ from 'lodash';

import { logout } from '../../api/user';
import Constants from '../../constants';
import initialState from '../../store/initial-state';

export default (draft, action) => {
  switch (action.type) {
    case Constants.ACTIONS.USER_LOGIN: {
      draft.isAuthenticated = true;
      break;
    }

    case Constants.ACTIONS.LOGOUT:
      logout();
      _.assign(draft, initialState);
      break;

    default:
  }
};
