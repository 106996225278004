import _ from 'lodash';

const getGstLines = (paydayDetail, isPractice) => {
  const gstItems = _(_.get(paydayDetail, 'gstItems'))
    .filter({
      isPractice: isPractice,
    })
    .map((item, index) => {
      const newItem = {};
      newItem['Payment'] = item.amount;
      newItem['GST'] = item.gst;
      newItem['Service ID'] = index;
      newItem['Invoice No.'] = item.invoiceNo;
      newItem['Patient Name'] = item.patientName;
      newItem['Description'] = item.description;
      newItem['providerId'] = item.providerId;
      return newItem;
    })
    .value();

  return gstItems;
};

export default getGstLines;
