import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker-custom.css';

import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Grid, Header, Message } from 'semantic-ui-react';

import PaydayAPI from '../../../../api/payday';
import Constants from '../../../../constants';
import { Context } from '../../../../store';
import { roundTo2DP } from '../../../../utils';
import CalculatorResetButton from '../common/calculator-reset-button';
import extractGstLines from '../invoice-calculator/extract-gst-lines';
import FinaliseButton from './finalise-button';
import OnlineScriptsInput from './online-script-input';

const FinalisePayday = () => {
  const { store, dispatch } = useContext(Context);
  const [periodStartDate, setPeriodStartDate] = useState(
    store.payday.billingPeriodStart
  );
  const [isFinalising, setIsFinalising] = useState(false);
  const [onlineScriptPeriod, setOnlineScriptPeriod] = useState('');
  const { payday } = store;

  const preparePaydayInvoices = () => {
    const invoices = [];
    _.forEach(payday.providerList, (provider) => {
      const invoice = {
        providerId: provider.id,
        onlineScripts: provider.onlineScripts,
        billingsTotal: provider.paymentTotal,
        billingsWithGst: provider.grossGst,
        netServiceFee: provider.netServiceFee,
        grossServiceFee: provider.grossServiceFee,
        procedureFees: provider.procedureFees,
        netBillings: provider.netBillings,
        directBillingsTotal: provider.directBillingsTotal,
      };
      invoices.push(invoice);
    });
    return invoices;
  };

  const prepareGstItems = () => {
    const gstLines = _.concat(
      extractGstLines(store.payday.providerList, true),
      extractGstLines(store.payday.providerList, false)
    );
    return _.map(gstLines, (line) => {
      return {
        providerId: line.providerId,
        invoiceNo: line['Invoice No.'],
        patientName: line['Patient Name'],
        description: line.Description,
        amount: line.Payment,
        gst: line.GST,
      };
    });
  };

  const preparePaydayPayload = () => {
    const periodStart = moment(periodStartDate, Constants.DATE_FORMAT);
    const periodEnd = moment(periodStartDate, Constants.DATE_FORMAT).add(
      13,
      'days'
    );

    const payload = {
      billingPeriodStart: periodStart.format(Constants.DATE_FORMAT_YEAR_FIRST),
      billingPeriodEnd: periodEnd.format(Constants.DATE_FORMAT_YEAR_FIRST),
      billingsTotal: payday.billingsTotal,
      directBillingsTotal: payday.directBillingsTotal,
      procedureFees: payday.procedureFees,
      procedureAdjustments: payday.procedureAdjustments,
      registrarOnlineScriptsTotal: payday.registrarOnlineScriptsTotal,
      registrarBillings: payday.registrarBillings,
      registrarFeesWithGst: payday.registrarFeesWithGst,
      feeTotal: payday.serviceFeeTotal,
      practiceChargesGst: payday.practiceChargesInclGST,
      practiceChargesGstFree: payday.practiceChargesGSTFree,
      onlineScripts: payday.onlineScriptsTotal,
      onlineScriptsDescription: onlineScriptPeriod,
      grandTotal: payday.grandTotal,
      invoices: preparePaydayInvoices(),
      gstItems: prepareGstItems(),
    };

    return payload;
  };

  const initiateFinalise = async () => {
    setIsFinalising(true);
    const payload = preparePaydayPayload();
    const success = await PaydayAPI.finalisePayday(payload, dispatch);
    setIsFinalising(false);
    if (success) {
      dispatch({
        type: Constants.ACTIONS.FINALISE_PAYDAY,
        payload: { paydayId: success },
      });
    } else {
      dispatch({ type: Constants.ACTIONS.RESET_PAYDAY });
    }
  };

  const handleDateChange = (date) => {
    setPeriodStartDate(date);
  };

  const handleInputChange = (input) => {
    setOnlineScriptPeriod(input);
  };

  // Ensure the distributed procedure fees reconcile with the total procedure fees
  const providerProceduresTotal = _.sumBy(payday.providerList, (provider) => {
    return provider.procedureFees;
  });
  const expectedFeesTotal = roundTo2DP(
    payday.procedureFees * Constants.PROCEDURE_MULTIPLIER
  );
  const isFeesReconciled = providerProceduresTotal === expectedFeesTotal;

  // Ensure the billing period start date is a Monday
  const isMonday = moment(periodStartDate).day() === 1;

  const buttonsDisabled = store.payday.stepNo >= 4 || isFinalising;

  return (
    <Grid.Row>
      <Grid.Column width={4}>
        <FinaliseButton
          disabled={buttonsDisabled}
          initiateFinalise={initiateFinalise}
          isLoading={isFinalising}
        />
        {store.payday.stepNo === 3 && !isFinalising && (
          <CalculatorResetButton />
        )}
      </Grid.Column>
      <Grid.Column width={12}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header sub>Billing Period Start</Header>
              <DatePicker
                selected={periodStartDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                disabled={buttonsDisabled}
                readOnly={buttonsDisabled}
                className="semantic-input"
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Header sub>Online Scripts Period (MM/YYYY)</Header>
              <OnlineScriptsInput
                disabled={buttonsDisabled}
                onChange={handleInputChange}
              />
            </Grid.Column>
          </Grid.Row>
          {!isFeesReconciled && (
            <Grid.Row>
              <Grid.Column width={10}>
                <Message warning>
                  Procedure Fees do not reconcile. Total should be{' '}
                  {expectedFeesTotal}. Total is {providerProceduresTotal}.
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
          {!isMonday && (
            <Grid.Row>
              <Grid.Column width={10}>
                <Message warning>Billing Period Start is not a Monday</Message>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export default FinalisePayday;
