import _ from 'lodash';
import Papa from 'papaparse';
import React, { useContext, useState } from 'react';

import PaymentsAPI from '../../../../../api/payments';
import PaymentsImportButton from '../payments-import-button';
import preparePaymentGroups from '../prepare-payment-groups';
import processPaymentFile from '../process-payment-file';
import ReconciliationActions from '../../../context';

const PaymentsImport = (props) => {
  const [isImporting, setIsImporting] = useState(false);
  const actions = useContext(ReconciliationActions);

  const { providerList } = props;

  const fileInputRef = React.createRef();

  const handleImportComplete = async (results) => {
    const paydayTotals = processPaymentFile(providerList, results.data);
    const payload = { paymentGroups: preparePaymentGroups(paydayTotals) };
    // TODO add try / catch to handle error
    await PaymentsAPI.finalisePaymentImport(payload);
    actions.reloadPayments();
    setIsImporting(false);
  };

  const handleFileChosen = (event) => {
    setIsImporting(true);

    const file = event.target.files[0];

    // TODO - throw error if first column of header row is not Location
    Papa.parse(file, {
      complete: handleImportComplete,
      header: true,
      transformHeader: (h) => {
        return _.replace(h, '\n($ incl GST)', '');
      },
    });
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  return (
    <PaymentsImportButton
      handleFileChosen={handleFileChosen}
      handleFileSelect={handleFileSelect}
      fileInputRef={fileInputRef}
      isLoading={isImporting}
      disabled={isImporting}
    />
  );
};

export default PaymentsImport;
