import moment from 'moment';

const getFinancialYear = (date) => {
  const m = moment(date);
  const year = m.get('year');
  const month = m.get('month');
  if (month + 1 <= 6) {
    return `${year - 1}/${year - 2000}`;
  }
  return `${year}/${year + 1 - 2000}`;
};

export default getFinancialYear;
