import React, { useState } from 'react';

import AbaGeneratorButton from './components/aba-generator-button';
import generateAba from './utils/generate-aba';

function AbaGenerator(props) {
  const [isGenerating, setIsGenerating] = useState(false);

  const performGeneration = async () => {
    setIsGenerating(true);
    await generateAba(props.paydayDetail);
    setIsGenerating(false);
  };

  return (
    <AbaGeneratorButton
      performGeneration={performGeneration}
      isGenerating={isGenerating}
    />
  );
}

export default AbaGenerator;
