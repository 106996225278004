import _ from 'lodash';
import moment from 'moment';

import Constants from '../../../../../../constants';
import convertPayment from '../../../../../../shared/payment-files/convert-payment';
import findMatchingProvider from '../../../../../../shared/payment-files/find-matching-provider';
import checkCustomItems from '../check-custom-items';
import extractReversedPayments from '../extract-reversed-payments';
import initialiseProviders from '../initialise-providers';
import isDirectBillingsPayment from '../is-direct-billings-payment';

const PROCEDURE_DESCRIPTIONS = [
  'Procedure room fee - IUD',
  'Procedure room fee - Tongue Tie',
];
const processPaymentFile = (
  providerList,
  paymentData,
  customFeeDescriptions
) => {
  const reversedPayments = extractReversedPayments(paymentData);

  const paydayTotals = {
    billingsTotal: 0,
    directBillingsTotal: 0,
    procedureFees: 0,
    procedureServices: [],
    paymentData: [],
    providerList: initialiseProviders(providerList),
    billingPeriodStart: moment(),
    paydayWarnings: [],
  };

  _.forEach(paymentData, (payment) => {
    if (!_.isNil(payment.Location) && !_.isEmpty(payment.Location)) {
      // Double check if payment should be for practice and record warnings
      // for any unknown items
      const convertedPayment = checkCustomItems(
        customFeeDescriptions,
        convertPayment(payment),
        paydayTotals.providerList
      );
      if (convertedPayment.warning) {
        paydayTotals.paydayWarnings.push(convertedPayment);
      }

      // Work out what type of original payment a Deposit Allocation is for and
      // adjust the payment method accordingly.
      // They are generally always for EFT, but check just in case and
      // record warning if no match can be found.
      if (
        _.toLower(convertedPayment['Payment Method']) === 'deposit allocation'
      ) {
        const matchedPaymentMethodsByInvoice = _(reversedPayments)
          .map((reversedPayment) => {
            if (
              reversedPayment['Invoice No.'] === convertedPayment['Invoice No.']
            ) {
              return reversedPayment['Payment Method'];
            }
            return null;
          })
          .compact()
          .uniq()
          .value();

        if (_.size(matchedPaymentMethodsByInvoice) !== 1) {
          paydayTotals.paydayWarnings.push({
            ...convertedPayment,
            warning: 'Unmatched deposit allocation',
          });
        } else {
          convertedPayment['Payment Method'] = _.head(
            matchedPaymentMethodsByInvoice
          );
        }
      }

      const provider = findMatchingProvider(
        paydayTotals.providerList,
        convertedPayment
      );

      const trxnDate = moment(
        convertedPayment['Transaction Date'],
        Constants.DATE_FORMAT
      );

      if (provider.independentDoctorStartDate) {
        if (trxnDate < provider.earliestTransactionDate) {
          provider.earliestTransactionDate = trxnDate;
        }
      }

      if (trxnDate < paydayTotals.billingPeriodStart) {
        paydayTotals.billingPeriodStart = trxnDate;
      }

      provider.paymentTotal += convertedPayment.Payment;

      if (
        isDirectBillingsPayment({
          provider,
          transactionDate: convertedPayment['Transaction Date'],
        })
      ) {
        provider.directBillingsTotal += convertedPayment.Payment;
        paydayTotals.directBillingsTotal += convertedPayment.Payment;
      }

      provider.gstTotal += convertedPayment.GST;
      provider.grossGst =
        convertedPayment.GST !== 0
          ? provider.grossGst + convertedPayment.Payment
          : provider.grossGst;
      provider.paymentData.push(convertedPayment);
      if (convertedPayment.GST !== 0) {
        provider.gstLines.push(convertedPayment);
      }
      paydayTotals.paymentData.push(convertedPayment);
      paydayTotals.billingsTotal += convertedPayment.Payment;

      const isProdedureFee = _.find(PROCEDURE_DESCRIPTIONS, (description) => {
        return _.startsWith(
          _.toLower(convertedPayment.Description),
          _.toLower(description)
        );
      });
      if (convertedPayment['Item No.'].length === 0 && isProdedureFee) {
        paydayTotals.procedureServices.push({
          serviceId: convertedPayment['Service ID'],
          description: convertedPayment.Description,
          amount: convertedPayment.Payment,
          patientName: convertedPayment['Patient Name'],
        });
        paydayTotals.procedureFees += convertedPayment.Payment;
      }
    }
  });

  return paydayTotals;
};

export default processPaymentFile;
