import _ from 'lodash';

const filterFortnightlyBillings = ({ finYears, fortnightlyBillings }) => {
  const filtered = _.cloneDeep(fortnightlyBillings);
  // Only display a maximum of 10 years of data
  _.set(filtered, 'finYears', _.takeRight(finYears, 10))
  return filtered;
};

export default filterFortnightlyBillings;
