import _ from 'lodash';
import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { formatNumber } from '../../../../../utils';

const chartColours = [
  '#54478C',
  '#2C699A',
  '#048BA8',
  '#0DB39E',
  '#16DB93',
  '#83E377',
  '#B9E769',
  '#EFEA5A',
  '#F1C453',
  '#F29E4C',
];

const BillingsChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="finYear" />
        <YAxis />
        <Tooltip
          formatter={(value) => {
            return `$${formatNumber(value)}`;
          }}
        />
        <Legend />
        <Bar name="Average Billings" dataKey="averageBillings">
          {_.map(data, (entry, index) => (
            <Cell key={`cell-${index}`} fill={chartColours[index]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BillingsChart;
