import moment from 'moment';
import React from 'react';
import { Grid} from 'semantic-ui-react';

import Constants from '../../../../constants';
import DateRangeSelector from '../../../components/date-range-selector';
import ReconciliationActions from '../../context';
import usePaymentsReconciliation from '../../use-payments-reconcilation';
import PaymentList from '../payment-list';
import { MODE_UNRECONCILE } from '../reconciliation-modes';

const ReconciledTabPane = () => {
  const periodStartDate = moment().subtract(14, 'days').toDate();
  const periodEndDate = moment().toDate();
  const searchPayload = {
    criteria: {
      reconciled: true,
      startDate: moment(periodStartDate).format(
        Constants.DATE_FORMAT_YEAR_FIRST
      ),
      endDate: moment(periodEndDate).format(Constants.DATE_FORMAT_YEAR_FIRST),
    },
  };

  const { actions, isLoading, data } = usePaymentsReconciliation(searchPayload);

  const handlePaymentSearch = (startDate, endDate) => {
    actions.searchPayments({
      criteria: {
        reconciled: true,
        startDate: moment(startDate).format(
          Constants.DATE_FORMAT_YEAR_FIRST
        ),
        endDate: moment(endDate).format(Constants.DATE_FORMAT_YEAR_FIRST),
      },
    });
  };

  return (
    <ReconciliationActions.Provider value={actions}>
      <Grid divided="vertically">
        <DateRangeSelector handleOnClick={handlePaymentSearch} isLoading={isLoading} startDate={periodStartDate} endDate={periodEndDate} />
        <PaymentList
          data={data}
          isLoading={isLoading}
          mode={MODE_UNRECONCILE}
        />
      </Grid>
    </ReconciliationActions.Provider>
  );
};

export default ReconciledTabPane;
