import React, { useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';

import filterAverageBillings from '../filter-average-billings';
import filterFortnightlyBillings from '../filter-fortnightly-billings';
import FinYearFilter from './fin-year-filter';
import AverageBillingsChart from './average-billings-chart';
import FornightlyBillingsChart from './fortnightly-billings-chart';
import manipulateBillingsData from '../manipulate-billings-data';

const BillingsChart = ({ data }) => {
  const {
    averageBillings,
    finYears,
    fortnightlyBillings,
  } = manipulateBillingsData(data);

  const [filteredAverage, setFilteredAverage] = useState(
    filterAverageBillings({ finYears, averageBillings })
  );
  const [filteredFortnightly, setFilteredFortnightly] = useState(
    filterFortnightlyBillings({ finYears, fortnightlyBillings })
  );

  const applyFilter = (finYears) => {
    setFilteredAverage(filterAverageBillings({ finYears, averageBillings }));
    setFilteredFortnightly(
      filterFortnightlyBillings({ finYears, fortnightlyBillings })
    );
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h2">Billings Over The Year</Header>
          <FornightlyBillingsChart data={filteredFortnightly} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h2">Average Billings</Header>
          <AverageBillingsChart data={filteredAverage} />
        </Grid.Column>
        <Grid.Column>
          <Header as="h2">Filter by Financial Year</Header>
          <FinYearFilter applyFilter={applyFilter} finYears={finYears} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BillingsChart;
