import _ from 'lodash';

import getFinancialYear from '../get-financial-year';

const manipulateBillingsData = (data) => {
  const newData = {};
  let fortnightIndex = 0;
  _.forEach(data, (row) => {
    const { billingsTotal, billingPeriodEnd } = row;
    const finYear = getFinancialYear(billingPeriodEnd);
    if (!_.get(newData, finYear)) {
      _.set(newData, finYear, []);
      fortnightIndex = 0;
    }
    fortnightIndex += 1;
    newData[finYear].push({
      periodIndex: fortnightIndex,
      billingsTotal,
      billingPeriodEnd,
      finYear,
    });
  });

  const aggregatedData = [];
  const finYears = [];
  const averageData = [];
  const totalsData = [];
  _.forEach(_.keys(newData), (finYear) => {
    finYears.push(finYear);
    const billingPeriods = newData[finYear];
    let periodCount = 0;
    let totalBillings = 0;
    _.forEach(billingPeriods, (billingPeriod) => {
      periodCount += 1;
      totalBillings += billingPeriod.billingsTotal;
      const existingPeriod = _.find(aggregatedData, {
        periodIndex: billingPeriod.periodIndex,
      });
      if (existingPeriod) {
        _.set(existingPeriod, finYear, billingPeriod.billingsTotal);
      } else {
        const newPeriod = {
          periodIndex: billingPeriod.periodIndex,
        };

        newPeriod[finYear] = billingPeriod.billingsTotal;
        aggregatedData.push(newPeriod);
      }
    });
    averageData.push({
      finYear,
      averageBillings: totalBillings / periodCount,
    });
    totalsData.push({
      finYear,
      total: totalBillings,
    });
  });

  return {
    fortnightlyBillings: { finYears, data: aggregatedData },
    averageBillings: averageData,
    totalBillings: totalsData,
    finYears,
  };
};

export default manipulateBillingsData;
