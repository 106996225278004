import React from 'react';
import { Button } from 'semantic-ui-react';

function FinaliseButton(props) {
  const { initiateFinalise, isLoading, disabled } = props;
  return (
    <>
      <Button
        basic
        fluid
        onClick={initiateFinalise}
        color="blue"
        content="3. Finalise Payday"
        icon="download"
        labelPosition="left"
        disabled={isLoading || disabled}
        loading={isLoading}
      />
    </>
  );
}

export default FinaliseButton;
