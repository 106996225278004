import classNames from 'classnames';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Icon, Input, Modal, Table } from 'semantic-ui-react';

import Constants from '../../../../../constants';
import { Context } from '../../../../../store';
import { formatNumber } from '../../../../../utils';
import GstLines from '../../gst-lines';
import styles from './styles.module.sass';

const ProviderInvoiceItem = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { dispatch } = useContext(Context);

  const {
    provider,
    allowOnlineScriptEntry,
    allowProcedureFeeEntry,
    isDisplayOnly,
    providerGstLines,
  } = props;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleScriptOnChange = (event) => {
    dispatch({
      type: Constants.ACTIONS.ADD_ONLINE_SCRIPT,
      payload: {
        value: event.target.value,
        providerName: provider.name,
      },
    });
  };

  const handleProcedureFeeOnChange = (event) => {
    dispatch({
      type: Constants.ACTIONS.ADJUST_PROCEDURE_FEES,
      payload: {
        value: event.target.value,
        providerName: provider.name,
      },
    });
  };

  const billingsWithGst = _.get(provider, 'grossGst');
  const hasGstItems = billingsWithGst !== 0;

  const renderOnlineScript = () => {
    if (isDisplayOnly) {
      return (
        <Table.Cell
          textAlign="right"
          className={classNames({ [styles.withGst]: hasGstItems })}
        >
          {formatNumber(provider.onlineScripts)}
        </Table.Cell>
      );
    }

    return (
      <Table.Cell>
        <Input
          className={styles.scriptInput}
          size="small"
          onChange={handleScriptOnChange}
          placeholder="Online scripts"
          disabled={!allowOnlineScriptEntry}
          data-testid="script-input"
        />
      </Table.Cell>
    );
  };

  const renderBillings = () => {
    let billings = formatNumber(provider.paymentTotal);

    if (hasGstItems) {
      const gstLines = _.filter(providerGstLines, {
        providerId: provider.providerId,
      });
      billings = (
        <>
          <span className={styles.paymentTotal}>
            {formatNumber(provider.paymentTotal)}
          </span>
          <Icon
            data-testid="gstlines-icon"
            className={styles.gstButton}
            color="blue"
            size="small"
            name="plus square outline"
            onClick={openModal}
          />
          <Modal
            dimmer="blurring"
            closeIcon
            data-testid="gstlines-modal"
            size="large"
            open={isModalOpen}
            onClose={closeModal}
          >
            <Modal.Content>
              <GstLines gstLines={gstLines} />
            </Modal.Content>
          </Modal>
        </>
      );
    }

    return billings;
  };

  const renderProcedureFees = () => {
    if (isDisplayOnly) {
      return formatNumber(provider.procedureFees);
    }

    if (provider.isIndependentDoctor) {
      return (
        <Input
          className={styles.scriptInput}
          size="small"
          onChange={handleProcedureFeeOnChange}
          disabled={!allowProcedureFeeEntry}
          defaultValue={formatNumber(provider.procedureFees)}
          data-testid="fees-input"
        />
      );
    }
    return null;
  };

  return (
    <Table.Row textAlign="center">
      <Table.Cell
        textAlign="left"
        className={classNames({ [styles.withGst]: hasGstItems })}
      >
        {provider.name}
      </Table.Cell>
      {renderOnlineScript()}
      <Table.Cell
        textAlign="right"
        className={classNames({ [styles.withGst]: hasGstItems })}
      >
        {renderBillings()}
      </Table.Cell>
      <Table.Cell
        textAlign="right"
        className={classNames({ [styles.withGst]: hasGstItems })}
      >
        {provider.isIndependentDoctor &&
          formatNumber(provider.paymentTotal - provider.directBillingsTotal)}
      </Table.Cell>
      <Table.Cell
        textAlign="right"
        className={classNames({ [styles.withGst]: hasGstItems })}
      >
        {provider.isIndependentDoctor && formatNumber(provider.netServiceFee)}
      </Table.Cell>
      <Table.Cell
        textAlign="right"
        className={classNames({ [styles.withGst]: hasGstItems })}
      >
        {provider.isIndependentDoctor && formatNumber(provider.grossServiceFee)}
      </Table.Cell>
      <Table.Cell
        textAlign="right"
        className={classNames({ [styles.withGst]: hasGstItems })}
      >
        {renderProcedureFees()}
      </Table.Cell>
      <Table.Cell
        textAlign="right"
        className={classNames({ [styles.withGst]: hasGstItems })}
      >
        {provider.isIndependentDoctor && formatNumber(provider.netBillings)}
      </Table.Cell>
    </Table.Row>
  );
};

export default ProviderInvoiceItem;
