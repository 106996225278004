import { roundTo2DP } from '../../../utils';

export default (provider) => {
  return roundTo2DP(
    provider.paymentTotal +
      provider.onlineScripts -
      provider.grossServiceFee +
      provider.procedureFees
  );
};
