import Constants from '../../../../constants';
import useApi from '../../../../api/use-api';

const usePaydayHistory = (searchPayload) => {
  const [{ isLoading, data }, setHistoryPayload] = useApi({
    type: Constants.LOAD.PAYDAY_HISTORY,
    options: searchPayload,
  });

  const searchHistory = (options) => {
    setHistoryPayload({
      type: Constants.LOAD.PAYDAY_HISTORY,
      options,
    });
  };

  return {
    actions: { searchHistory },
    isLoading,
    data,
  };
};

export default usePaydayHistory;
