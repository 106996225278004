import Constants from '../../../constants';
import useApi from '../../../api/use-api';
import useActions from './use-actions';

const usePaymentsReconciliation = (searchPayload) => {
  const [state, actions] = useActions();

  const [
    { isLoading: isLoadingProviders, data: fetchedProviderData },
  ] = useApi({ type: Constants.LOAD.PROVIDER_LIST });

  const [
    { isLoading: isLoadingPayments, data: fetchedPaymentsData },
    setPaymentsPayload,
  ] = useApi({
    type: Constants.LOAD.PAYMENT_GROUPS,
    options: searchPayload,
  });

  const reloadPayments = () => {
    setPaymentsPayload({
      type: Constants.LOAD.PAYMENT_GROUPS,
      options: { criteria: { reconciled: false } },
    });
  };

  const searchPayments = (options) => {
    setPaymentsPayload({
      type: Constants.LOAD.PAYMENT_GROUPS,
      options,
    });
  };

  const {
    isLoading,
    latestPaymentDate,
    paymentsData,
    providerData,
    selectedPayments,
  } = state;

  if ((isLoadingProviders || isLoadingPayments) && !isLoading) {
    actions.setIsLoading(true);
  }

  if (isLoading && !isLoadingProviders && !isLoadingPayments) {
    const payload = {
      providerData: fetchedProviderData,
      paymentsData: fetchedPaymentsData,
    };
    actions.finishLoad(payload);
  }

  return {
    actions: { ...actions, reloadPayments, searchPayments },
    isLoading,
    data: {
      paymentsData,
      providerData,
      selectedPayments,
      latestPaymentDate,
    },
  };
};

export default usePaymentsReconciliation;
