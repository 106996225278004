import _ from 'lodash';

export default (providerList, payment, byProviderId = false) => {
  let filtered;

  if (byProviderId) {
    filtered = _.filter(providerList, (provider) => {
      return _.toLower(provider.id) === _.toLower(payment.providerId);
    });
  } else {
    filtered = _.filter(providerList, (provider) => {
      return _.toLower(provider.name) === _.toLower(payment.Provider);
    });
  }

  if (filtered.length === 0) {
    filtered = _.filter(providerList, (provider) => {
      return _.toLower(provider.name) === 'unknown';
    });
  }

  return filtered[0];
};
