import _ from 'lodash';
import moment from 'moment';

import Constants from '../constants';

export default (draft, action) => {
  switch (action.type) {
    case Constants.ACTIONS.LOADING_PAYMENTS_START:
      draft.payday.isLoadingPayments = true;
      break;

    case Constants.ACTIONS.LOADING_PAYMENTS_COMPLETE:
      draft.payday.isLoadingPayments = false;
      draft.payday.stepNo = 2;
      _.forEach(action.payload.providerList, (provider) => {
        if (
          provider.independentDoctorStartDate &&
          moment(provider.independentDoctorStartDate).isAfter(
            provider.earliestTransactionDate
          )
        ) {
          _.set(provider, 'isIndependentDoctor', false);
        }
      });

      draft.payday.providerList = action.payload.providerList;
      draft.payday.paymentData = action.payload.paymentData;
      draft.payday.gstLines = action.payload.gstLines;
      draft.payday.billingsTotal = action.payload.billingsTotal;
      draft.payday.grandTotal = action.payload.billingsTotal;
      draft.payday.directBillingsTotal = action.payload.directBillingsTotal;
      draft.payday.procedureFees = action.payload.procedureFees;
      draft.payday.billingPeriodStart = action.payload.billingPeriodStart.toDate();
      draft.payday.paydayWarnings = action.payload.paydayWarnings;
      break;

    default:
  }
};
