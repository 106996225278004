import classnames from 'classnames';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';

import PaymentsAPI from '../../../../api/payments';
import { formatNumber } from '../../../../utils';
import ReconciliationActions from '../../context';
import { MODE_RECONCILE } from '../reconciliation-modes';
import styles from './styles.module.sass';

const PaymentItem = ({
  blockSingleReconciliation,
  filterValue,
  isSelected,
  payment,
  mode,
}) => {
  const [isReconciling, setIsReconciling] = useState(false);
  const actions = useContext(ReconciliationActions);

  const toggleSelected = () => {
    actions.paymentRowToggle(payment);
  };

  const isReconcileMode = mode === MODE_RECONCILE;
  const buttonColor = isReconcileMode ? 'blue' : 'orange';

  const paymentReconciled = async (event) => {
    setIsReconciling(true);
    event.preventDefault();
    await PaymentsAPI.reconcilePaymentGroups({
      paymentGroupIds: [payment.id],
      reconcile: isReconcileMode,
    });
    // TODO add try / catch to handle error
    setIsReconciling(false);
    actions.paymentsReconciled([payment.id]);
  };

  if (
    !_.isEmpty(filterValue) &&
    !_.startsWith(_.get(payment, 'type'), filterValue) &&
    filterValue !== 'Cash / Cheque'
  ) {
    return null;
  }

  if (
    filterValue === 'Cash / Cheque' &&
    !_.includes(['Cash', 'Cheque'], _.get(payment, 'type'))
  ) {
    return null;
  }

  return (
    <Table.Row
      verticalAlign="middle"
      onClick={toggleSelected}
      className={classnames(styles.row, { [styles.selected]: isSelected })}
    >
      <Table.Cell textAlign="right">{payment.groupDate}</Table.Cell>
      <Table.Cell textAlign="right">{formatNumber(payment.amount)}</Table.Cell>
      <Table.Cell textAlign="right">{formatNumber(payment.gst)}</Table.Cell>
      <Table.Cell textAlign="center">
        <Button
          size="mini"
          disabled={blockSingleReconciliation}
          loading={isReconciling}
          basic
          color={buttonColor}
          icon
          onClick={paymentReconciled}
        >
          <Icon name={isReconcileMode ? 'check' : 'close'} />
        </Button>
      </Table.Cell>
      <Table.Cell textAlign="left">
        {payment.name && `${payment.name} (${payment.providerNo})`}
      </Table.Cell>
      <Table.Cell textAlign="center">{payment.type}</Table.Cell>
      <Table.Cell textAlign="left">{payment.paymentId}</Table.Cell>
      <Table.Cell textAlign="left">{payment.invoiceNo}</Table.Cell>
    </Table.Row>
  );
};

export default PaymentItem;
