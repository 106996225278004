import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Grid, Header, Icon, Table } from 'semantic-ui-react';

import Loader from '../../../components/loader';
import Constants from '../../../constants';
import { formatNumber } from '../../../utils';
import DateRangeSelector from '../../components/date-range-selector';
import ViewButton from './components/view-button';
import styles from './styles.module.sass';
import usePaydayHistory from './use-payday-history';

const PaydayHistory = () => {
  const periodStartDate = moment().subtract(140, 'days').toDate();
  const periodEndDate = moment().toDate();
  const searchPayload = {
    criteria: {
      startDate: moment(periodStartDate).format(
        Constants.DATE_FORMAT_YEAR_FIRST
      ),
      endDate: moment(periodEndDate).format(Constants.DATE_FORMAT_YEAR_FIRST),
    },
  };

  const { actions, isLoading, data } = usePaydayHistory(searchPayload);

  const handleHistorySearch = (startDate, endDate) => {
    actions.searchHistory({
      criteria: {
        startDate: moment(startDate).format(Constants.DATE_FORMAT_YEAR_FIRST),
        endDate: moment(endDate).format(Constants.DATE_FORMAT_YEAR_FIRST),
      },
    });
  };

  return (
    <>
      <Header as="h1">
        <Icon name="archive" />
        <Header.Content>Payday History</Header.Content>
      </Header>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={styles.contentWrapper}>
          <Grid divided="vertically">
            <DateRangeSelector
              handleOnClick={handleHistorySearch}
              isLoading={isLoading}
              startDate={periodStartDate}
              endDate={periodEndDate}
            />
          </Grid>
          <Table striped selectable>
            <Table.Header>
              <Table.Row verticalAlign="top">
                <Table.HeaderCell>Period </Table.HeaderCell>
                <Table.HeaderCell>Billings Total</Table.HeaderCell>
                <Table.HeaderCell>Fee Total</Table.HeaderCell>
                <Table.HeaderCell>
                  Registrar
                  <br />
                  Billings
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Procedure
                  <br />
                  Fees
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Practice
                  <br />
                  Charges
                  <br />
                  <span className={styles.small}>(with GST)</span>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Practice
                  <br />
                  Charges
                  <br />
                  <span className={styles.small}>(GST free)</span>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Online
                  <br />
                  Scripts
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(data, (payday) => (
                <Table.Row key={payday.id} verticalAlign="top">
                  <Table.Cell>
                    {payday.billingPeriodStart} to <br />
                    {payday.billingPeriodEnd}
                  </Table.Cell>
                  <Table.Cell>{formatNumber(payday.billingsTotal)}</Table.Cell>
                  <Table.Cell>{formatNumber(payday.feeTotal)}</Table.Cell>
                  <Table.Cell>
                    {formatNumber(payday.registrarBillings)}
                  </Table.Cell>
                  <Table.Cell>{formatNumber(payday.procedureFees)}</Table.Cell>
                  <Table.Cell>
                    {formatNumber(payday.practiceChargesWithGst)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatNumber(payday.practiceChargesGstFree)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatNumber(payday.onlineScripts)}
                    <br />
                    <span className={styles.small}>
                      {payday.onlineScriptsPeriod}
                    </span>
                  </Table.Cell>
                  <Table.Cell>
                    <ViewButton paydayId={payday.id} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );
};

export default PaydayHistory;
