import React, { useState } from 'react';

import InvoiceExporterButton from './components/invoice-exporter-button';
import exportInvoices from './utils/export-invoices';

function InvoiceExporter(props) {
  const [isExporting, setIsExporting] = useState(false);

  const performExport = async () => {
    setIsExporting(true);
    await exportInvoices(props.paydayDetail);
    setIsExporting(false);
  };

  return (
    <InvoiceExporterButton
      performExport={performExport}
      isExporting={isExporting}
    />
  );
}

export default InvoiceExporter;
