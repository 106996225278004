import React from 'react';
import { Grid } from 'semantic-ui-react';

import Loader from '../../components/loader';
import Constants from '../../constants';
import useApi from '../../api/use-api';
import BillingsChart from './charts/billings-chart';

const Home = () => {
  const chartsCriteria = {
    type: Constants.LOAD.PAYDAY_HISTORY,
    options: { orderBy: { field: 'billingPeriodEnd', order: 'ASC' } },
  };
  const [{ isLoading, data }] = useApi(chartsCriteria);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Grid celled="internally">
          <Grid.Row>
            <Grid.Column>
              <BillingsChart data={data} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

export default Home;
