import axios from 'axios';
import _ from 'lodash';

import Constants from '../../constants';
import { getJWTHeader } from '../../utils';

const PaydayAPI = {
  getPaydayHistory: (options) => {
    const body = {};
    if (options) {
      _.assign(body, { orderBy: options.orderBy }, options.criteria);
    }
    const authHeader = getJWTHeader();
    return axios.post(`${Constants.API_BASE}/payday`, body, authHeader);
  },
  getPaydayDetail: (paydayId) => {
    const authHeader = getJWTHeader();
    return axios.post(
      `${Constants.API_BASE}/payday/${paydayId}`,
      {},
      authHeader
    );
  },
  finalisePayday: async (payload, dispatch) => {
    const authHeader = getJWTHeader();
    try {
      const response = await axios.post(
        `${Constants.API_BASE}/payday/create`,
        payload,
        authHeader
      );
      return response.data.id;
    } catch (error) {
      dispatch({
        type: Constants.ACTIONS.API_ERROR,
        payload: { status: error.response.status },
      });
      return false;
    }
  },
};

export default PaydayAPI;
