const initialPaydayState = {
  isLoadingPayments: false,
  stepNo: 1,
  providerList: [],
  paymentData: [],
  gstLines: [],
  billingPeriodStart: new Date(),
  billingPeriodEnd: '',
  billingsTotal: 0,
  procedureFees: 0,
  procedureAdjustments: 0,
  registrarBillings: 0,
  registrarFeesWithGst: 0,
  serviceFeeTotal: 0,
  registrarFeeTotal: 0,
  registrarOnlineScriptsTotal: 0,
  practiceChargesInclGST: 0,
  practiceChargesGSTFree: 0,
  onlineScriptsTotal: 0,
  onlineScriptsDescription: '',
  grandTotal: 0,
};

const initialState = {
  isAuthenticated: false,
  jwt: '',
  apiError: false,
  apiStatusCode: '',
  providerList: [],
  paydayDetail: {},
  payday: initialPaydayState,
};

export default initialState;
export { initialPaydayState };
