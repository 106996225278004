import _ from 'lodash';

const extractGstLines = (providerList, isPractice) => {
  return _.flatMap(providerList, (provider) => {
    return provider.isPractice === isPractice
      ? _.map(_.get(provider, 'gstLines'), (gstLine) => {
          return { ...gstLine, providerId: provider.id };
        })
      : [];
  });
};

export default extractGstLines;
