import _ from 'lodash';
import moment from 'moment';

import isArchivedProvider from '../../../../../../shared/is-archived-provider';

export default (providerList) => {
  return _.compact(
    _.map(providerList, (provider) => {
      if (isArchivedProvider({ provider })) {
        return null;
      }
      return _.assign({}, provider, {
        paymentTotal: 0,
        onlineScripts: 0,
        procedureFees: 0,
        gstTotal: 0,
        grossGst: 0,
        earliestTransactionDate: moment(),
        paymentData: [],
        gstLines: [],
        directBillingsTotal: 0,
        providerId: provider.id,
      });
    })
  );
};
