import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Header, Icon, Popup, Table } from 'semantic-ui-react';

import Loader from '../../components/loader';
import Constants from '../../constants';
import useApi from '../../api/use-api';
import styles from './styles.module.sass';

const Providers = () => {
  const [{ isLoading, data }] = useApi({ type: Constants.LOAD.PROVIDER_LIST });

  const providerIcon = (provider) => {
    let icon = 'question';
    let type = 'Unknown';

    if (provider.isIndependentDoctor) {
      icon = 'doctor';
      type = 'Independent Doctor';
    } else if (provider.isPractice) {
      icon = 'home';
      type = 'Practice';
    } else if (provider.id !== '999') {
      icon = 'student';
      type = 'Registrar';
    }
    return (
      <Popup
        trigger={<Icon name={icon} size="large" />}
        content={type}
        size="mini"
        position="left center"
      />
    );
  };

  return (
    <>
      <Header as="h1">
        <Icon name="users" />
        <Header.Content>Providers</Header.Content>
      </Header>
      {isLoading && <Loader />}
      {!isLoading && (
        <div data-testid="provider-table" className={styles.contentWrapper}>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Provider No</Table.HeaderCell>
                <Table.HeaderCell>
                  <>
                    Independent Doctor
                    <br />
                    Start Date
                  </>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Provider
                  <br />
                  Type
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(data, (provider) => (
                <Table.Row key={provider.id}>
                  <Table.Cell>{provider.name}</Table.Cell>
                  <Table.Cell>{provider.providerNo}</Table.Cell>
                  <Table.Cell>
                    {provider.independentDoctorStartDate
                      ? moment(provider.independentDoctorStartDate).format(
                          Constants.DATE_FORMAT
                        )
                      : ''}
                  </Table.Cell>
                  <Table.Cell textAlign="center" collapsing>
                    {providerIcon(provider)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );
};

export default Providers;
