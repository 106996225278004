import _ from 'lodash';
import { useImmer } from 'use-immer';

const initialState = {
  isLoading: false,
  latestPaymentDate: null,
  paymentData: {},
  providerData: {},
  selectedPayments: [],
};

const useActions = () => {
  const [state, updateState] = useImmer(initialState);

  const clearSelectedPayments = () => {
    updateState((draft) => {
      _.set(draft, 'selectedPayments', []);
    });
  };

  const finishLoad = (payload) => {
    updateState((draft) => {
      _.set(draft, 'providerData', payload.providerData);
      _.set(draft, 'paymentsData', payload.paymentsData.payments);
      _.set(draft, 'isLoading', false);
      _.set(draft, 'latestPaymentDate', payload.paymentsData.latestPaymentDate);
    });
  };

  const paymentsReconciled = (payload) => {
    updateState((draft) => {
      const paymentIds = payload;
      const unreconciled = _.filter(draft.paymentsData, (payment) => {
        return !_.includes(paymentIds, payment.id);
      });
      _.set(draft, 'paymentsData', unreconciled);
      draft.selectedPayments = [];
    });
  };

  const paymentRowToggle = (payload) => {
    updateState((draft) => {
      const idx = _.findIndex(draft.selectedPayments, {
        id: payload.id,
      });
      if (idx >= 0) {
        draft.selectedPayments.splice(idx, 1);
      } else {
        draft.selectedPayments.push(payload);
      }
    });
  };

  const setIsLoading = (isLoading) => {
    updateState((draft) => {
      draft.isLoading = isLoading;
    });
  };

  const actions = {
    clearSelectedPayments,
    finishLoad,
    paymentsReconciled,
    paymentRowToggle,
    setIsLoading,
  };

  return [state, actions];
};

export default useActions;
