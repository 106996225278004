import _ from 'lodash';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { formatNumber } from '../../../../utils';

const GstLines = (props) => {
  const { gstLines } = props;
  let paymentTotal = 0;
  let gstTotal = 0;

  return (
    <Table color="blue" celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="5">GST Lines</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(gstLines, (line, index) => {
          paymentTotal += line.Payment;
          gstTotal += line.GST;
          return (
            <Table.Row key={`${line['Service ID']}-${index}`}>
              <Table.Cell>{line['Invoice No.']}</Table.Cell>
              <Table.Cell>{line['Patient Name']}</Table.Cell>
              <Table.Cell>{line.Description}</Table.Cell>
              <Table.Cell textAlign="right">
                {formatNumber(line.Payment)}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {formatNumber(line.GST)}
              </Table.Cell>
            </Table.Row>
          );
        })}
        <Table.Row key="-1">
          <Table.Cell colSpan="3">
            <b>Totals</b>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <b>{formatNumber(paymentTotal).toLocaleString('en')}</b>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <b>{formatNumber(gstTotal).toLocaleString('en')}</b>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default GstLines;
