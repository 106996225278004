import _ from 'lodash';

const filterAverageBillings = ({ finYears, averageBillings }) => {
  const filtered = _.filter(averageBillings, (average) => {
    return _.find(finYears, (finYear) => {
      return finYear === average.finYear;
    });
  });

  // Only display a maximum of 10 years of data
  return _.takeRight(filtered, 10);
};

export default filterAverageBillings;
