import React from 'react';
import { Image } from 'semantic-ui-react';

import logo from '../../assets/images/logo.png';
import styles from './styles.module.sass';

const Loader = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div>
        <div className={styles.logoWrapper}>
          <Image verticalAlign="middle" size="tiny" src={logo} />
        </div>
        <div className={styles.loaderText}>Loading...</div>
      </div>
    </div>
  );
};

export default Loader;
