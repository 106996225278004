import axios from 'axios';

import Constants from '../../constants';
import { getJWTHeader } from '../../utils';

const ProviderAPI = {
  getProviders: () => {
    const authHeader = getJWTHeader();
    return axios.post(`${Constants.API_BASE}/provider`, {}, authHeader);
  },
};

export default ProviderAPI;
