import _ from 'lodash';

import isDirectBillingsPayment from '../../../../utils/is-direct-billings-payment';

const checkForPaymentWarnings = (
  customFeeDescriptions,
  paydayCustomItem,
  provider
) => {
  // Compare the payment against the known list of practice fees and
  // report any unknown items.
  const matchedDescription = _.find(
    customFeeDescriptions,
    (customFeeDescription) => {
      return (
        _.toLower(paydayCustomItem.Description) ===
        _.toLower(customFeeDescription.itemDescription)
      );
    }
  );

  // No matching custom description found for payday item
  if (!matchedDescription) {
    return { ...paydayCustomItem, warning: 'Unknown item' };
  }
  // Check here if the payday item has been billed to the correct provider
  if (
    !matchedDescription.isPracticeFee &&
    _.toLower(paydayCustomItem.Provider) === 'stepping stones medical centre'
  ) {
    return { ...paydayCustomItem, warning: 'Bill to doctor' };
  }

  // NB. At this point in time if an item has been incorrectly billed to the
  // doctor, we adjust the provider to be the practice. This way the billings
  // calculations will reduce the doctors billings by the corrected amounts.
  // Note, this only works in the scenario where doctors billings are being
  // collected into a holding account.
  // For the model where the billings go direct to the doctor,
  // this approach is defunct as the doctor will have already received the funds.
  // We need to ensure that practice items are billed to the patient on
  // a separate invoice.
  if (
    matchedDescription.isPracticeFee &&
    _.toLower(paydayCustomItem.Provider) !== 'stepping stones medical centre'
  ) {
    if (
      isDirectBillingsPayment({
        provider,
        transactionDate: paydayCustomItem['Transaction Date'],
      })
    ) {
      return { ...paydayCustomItem, warning: 'Direct billings practice fee' };
    }
    return { ...paydayCustomItem, Provider: 'Stepping Stones Medical Centre' };
  }

  return paydayCustomItem;
};

export default checkForPaymentWarnings;
