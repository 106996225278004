import axios from 'axios';

import Constants from '../../constants';
import { getJWTHeader } from '../../utils';

const customFeeItemsAPI = {
  getCustomFeeItems: () => {
    const authHeader = getJWTHeader();
    return axios.post(`${Constants.API_BASE}/custom-fee-items`, {}, authHeader);
  },
};

export default customFeeItemsAPI;
