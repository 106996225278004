import _ from 'lodash';
import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';

import Constants from '../../../../../constants';
import { Context } from '../../../../../store';
import styles from './styles.module.sass';

const CalculatorResetButton = (props) => {
  const { dispatch } = useContext(Context);

  const resetCalculations = () => {
    dispatch({ type: Constants.ACTIONS.RESET_PAYDAY });
  };

  return (
    <div className={styles.resetButton}>
      <Button
        basic
        fluid
        onClick={resetCalculations}
        color="red"
        content="Restart"
        icon="history"
        labelPosition="left"
        disabled={_.get(props, 'disabled')}
      />
    </div>
  );
};

export default CalculatorResetButton;
