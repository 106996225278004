import Constants from '../constants';
import { initialPaydayState } from '../store/initial-state';

export default (draft, action) => {
  switch (action.type) {
    case Constants.ACTIONS.FINALISE_PAYDAY:
      draft.payday.stepNo = 4;
      draft.payday.paydayId = action.payload.paydayId;
      break;

    case Constants.ACTIONS.RESET_PAYDAY:
      draft.payday = initialPaydayState;
      break;

    case Constants.ACTIONS.SET_PAYDAY_STEP_NO:
      draft.payday.stepNo = action.payload.stepNo;
      break;

    default:
  }
};
