import _ from 'lodash';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Header, Icon } from 'semantic-ui-react';

import useApi from '../../../api/use-api';
import Loader from '../../../components/loader';
import Constants from '../../../constants';
import AbaGenerator from '../components/aba-generator';
import CalculatorStats from '../components/common/calculator-stats';
import ProviderInvoiceList from '../components/common/provider-invoice-list';
import InvoiceExporter from '../components/invoice-exporter';
import getGstLines from './get-gst-lines';
import Paginator from './paginator';
import transformPaydayDetails from './transform-payday-details';

const getApiPayload = (paydayId) => {
  return {
    type: Constants.LOAD.PAYDAY_DETAIL,
    options: { paydayId },
  };
};

const PaydayHistoryDetail = (props) => {
  const paydayId = _.get(props, 'match.params.id');
  const [{ isLoading, data }, setPayload] = useApi(getApiPayload(paydayId));

  useEffect(() => {
    setPayload(getApiPayload(paydayId));
  }, [paydayId, setPayload]);

  const paydayDetail = transformPaydayDetails(data);
  const practiceGstLines = getGstLines(paydayDetail, true);
  const providerGstLines = getGstLines(paydayDetail, false);

  return (
    <>
      <Header as="h1">
        <Icon name="archive" />
        <Header.Content>Payday Detail</Header.Content>
      </Header>
      {isLoading && <Loader />}
      {!isLoading && !_.isEmpty(paydayDetail) && (
        <Grid celled="internally">
          <Grid.Row>
            <Grid.Column width={4}>
              <InvoiceExporter paydayDetail={paydayDetail} />
            </Grid.Column>
            <Grid.Column width={4}>
              <AbaGenerator paydayDetail={paydayDetail} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid verticalAlign="middle">
                <Grid.Row columns={2}>
                  <Grid.Column width={6}>
                    <Header as="h2">
                      {`Period: ${paydayDetail.billingStartDate} to ${paydayDetail.billingEndDate}`}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Paginator
                      next={_.get(paydayDetail, 'nextId')}
                      prev={_.get(paydayDetail, 'prevId')}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <CalculatorStats
                payday={paydayDetail}
                gstLines={practiceGstLines}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <ProviderInvoiceList
                payday={paydayDetail}
                providerList={paydayDetail.providerList}
                providerGstLines={providerGstLines}
                isDisplayOnly
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

export default withRouter(PaydayHistoryDetail);
