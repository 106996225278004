import _ from 'lodash';
import React, { useState } from 'react';
import { Dropdown, Grid, Table } from 'semantic-ui-react';

import Loader from '../../../../components/loader';
import PaymentItem from '../payment-item';
import SelectedPaymentsAggregator from '../selected-payments-aggregator';
import styles from './styles.module.sass';

const paymentTypeOptions = [
  { key: 'cash-cheque', text: 'Cash / Cheque', value: 'Cash / Cheque' },
  {
    key: 'deposit-allocation',
    text: 'Deposit Allocation',
    value: 'Deposit Allocation',
  },
  { key: 'dva', text: 'DVA', value: 'DVA' },
  { key: 'eft', text: 'EFT', value: 'EFT' },
  { key: 'medicare', text: 'Medicare', value: 'Medicare' },
];

const PaymentList = ({ data, isLoading, mode }) => {
  const [filterValue, setFilterValue] = useState('');
  const handlePaymentTypeChange = (e, { value }) => {
    setFilterValue(value);
  };

  const { providerData, paymentsData, selectedPayments } = data;
  const multiRowsSelected = !_.isEmpty(selectedPayments);

  return (
    <Grid.Row>
      <Grid.Column>
        <div className={styles.contentWrapper}>
          <Grid>
            <Grid.Row className={styles.aggregatorRow}>
              <Grid.Column width={4}>
                <Dropdown
                  disabled={multiRowsSelected}
                  value={filterValue}
                  onChange={handlePaymentTypeChange}
                  placeholder="Filter by Payment Type"
                  clearable
                  options={paymentTypeOptions}
                  selection
                />
              </Grid.Column>
              <Grid.Column width={12}>
                {multiRowsSelected && (
                  <SelectedPaymentsAggregator
                    selectedPayments={selectedPayments}
                    mode={mode}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table compact celled fixed>
            <Table.Header>
              <Table.Row textAlign="center" verticalAlign="top">
                <Table.HeaderCell width={5}>Date</Table.HeaderCell>
                <Table.HeaderCell width={4}>Amount</Table.HeaderCell>
                <Table.HeaderCell width={4}>GST</Table.HeaderCell>
                <Table.HeaderCell width={3}></Table.HeaderCell>
                <Table.HeaderCell width={10}>Provider</Table.HeaderCell>
                <Table.HeaderCell width={8}>Type</Table.HeaderCell>
                <Table.HeaderCell width={4}>Payment Id</Table.HeaderCell>
                <Table.HeaderCell width={4}>Invoice No</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {!isLoading && !_.isNil(providerData) && !_.isEmpty(paymentsData) && (
              <Table.Body>
                {_.map(paymentsData, (payment) => {
                  const isSelected = _.some(selectedPayments, {
                    id: payment.id,
                  });
                  return (
                    <PaymentItem
                      blockSingleReconciliation={multiRowsSelected}
                      key={payment.id}
                      isSelected={isSelected}
                      payment={payment}
                      filterValue={filterValue}
                      mode={mode}
                    />
                  );
                })}
              </Table.Body>
            )}
            {!isLoading && !_.isNil(providerData) && _.isEmpty(paymentsData) && (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan="8" textAlign="left">
                    No payments found...
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
          {isLoading && <Loader />}
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

export default PaymentList;
