import Constants from '../../../../../../../constants';
import zeroFill from '../../../../../../../pages/payday/common/zero-fill';
import { formatNumber } from '../../../../../../../utils';

const invoicePrefix = 'SFA-';

const invoiceTemplateLine = {
  ContactName: '',
  EmailAddress: '',
  POAddressLine1: '',
  POAddressLine2: '',
  POAddressLine3: '',
  POAddressLine4: '',
  POCity: '',
  PORegion: '',
  POPostalCode: '',
  POCountry: '',
  InvoiceNumber: '',
  Reference: '',
  InvoiceDate: '',
  DueDate: '',
  InventoryItemCode: '',
  Description: '',
  Quantity: '',
  UnitAmount: '',
  Discount: '',
  AccountCode: '',
  TaxType: '',
  TrackingName1: '',
  TrackingOption1: '',
  TrackingName2: '',
  TrackingOption2: '',
  Currency: '',
  BrandingTheme: '',
};

const fillInvoiceDetails = (provider, invoiceNumber, invoiceDate, dueDate) => {
  const invoiceLine = { ...invoiceTemplateLine };
  invoiceLine.ContactName = provider.name;
  invoiceLine.InvoiceNumber = invoiceNumber;
  invoiceLine.InvoiceDate = invoiceDate;
  invoiceLine.DueDate = dueDate;

  return invoiceLine;
};

const createInvoiceLinesForProvider = ({
  dueDateStr,
  onlineScriptsPeriod,
  periodStartStr,
  periodEndStr,
  provider,
  suffixNumber,
}) => {
  const invoiceSuffix = `${suffixNumber}-${zeroFill(provider.providerId, 2)}`;
  const invoiceArray = [];

  // First line has Period details
  const invoiceLine1 = fillInvoiceDetails(
    provider,
    invoicePrefix + invoiceSuffix,
    periodEndStr,
    dueDateStr
  );

  invoiceLine1.Description = `Billing Period: ${periodStartStr} to ${periodEndStr}`;
  invoiceArray.push(invoiceLine1);

  if (provider.directBillingsTotal !== 0) {
    // Second line has Total Billings
    const invoiceLine2 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine2.Description = `Total Billings: $${formatNumber(
      provider.paymentTotal + provider.onlineScripts
    )}`;
    invoiceArray.push(invoiceLine2);

    // If all billings are direct, then don't show either direct or indirect billings
    const totalIndirectBillings =
      provider.paymentTotal +
      provider.onlineScripts -
      provider.directBillingsTotal;

    if (totalIndirectBillings !== 0) {
      // Third line has Direct Billings
      const invoiceLine3 = fillInvoiceDetails(
        provider,
        invoicePrefix + invoiceSuffix,
        periodEndStr,
        dueDateStr
      );
      invoiceLine3.Description = `Total Direct Billings: $${formatNumber(
        provider.directBillingsTotal
      )}`;
      invoiceArray.push(invoiceLine3);

      // Fourth line has Indirect Billings
      const totalIndirectWithGST = provider.grossGst + provider.onlineScripts;

      const invoiceLine4 = fillInvoiceDetails(
        provider,
        invoicePrefix + invoiceSuffix,
        periodEndStr,
        dueDateStr
      );
      invoiceLine4.Description = `Total Indirect Billings: $${formatNumber(
        totalIndirectBillings
      )}. Indirect Billings GST Free: $${formatNumber(
        totalIndirectBillings - totalIndirectWithGST
      )}. Indirect Billings with GST: $${formatNumber(totalIndirectWithGST)} ${
        totalIndirectWithGST !== 0
          ? `( ${
              provider.onlineScripts !== 0
                ? `Online Repeats - ${onlineScriptsPeriod}: $${formatNumber(
                    provider.onlineScripts
                  )}. `
                : ''
            }${
              provider.grossGst !== 0
                ? `Medical Reports: $${formatNumber(provider.grossGst)}. `
                : ''
            })`
          : ''
      }`;
      invoiceArray.push(invoiceLine4);
    }

    // Fifth line has procedure billings
    if (provider.procedureFees !== 0) {
      const invoiceLine5 = fillInvoiceDetails(
        provider,
        invoicePrefix + invoiceSuffix,
        periodEndStr,
        dueDateStr
      );
      invoiceLine5.Description = `Procedure Billings: $${formatNumber(
        provider.procedureFees
      )}`;
      invoiceArray.push(invoiceLine5);
    }

    // Sixth line has service fee
    const invoiceLine6 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine6.Description = `Service & Facilities Administration Fee (35% of Billings): $${formatNumber(
      provider.netServiceFee
    )} + GST`;

    invoiceArray.push(invoiceLine6);

    // Seventh line has net billings
    const invoiceLine7 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine7.Description = `Net Billings: $${formatNumber(
      provider.netBillings
    )}`;
    invoiceArray.push(invoiceLine7);

    // Eighth line is the actual fee line including quantity, amount, tax account etc
    const invoiceLine8 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine8.Description = 'Service & Facilities Administration Fee';
    invoiceLine8.Quantity = 1;
    invoiceLine8.UnitAmount = provider.netServiceFee;
    invoiceLine8.AccountCode = Constants.XERO_FEE_ACCOUNT_CODE;
    invoiceLine8.TaxType = Constants.XERO_FEE_TAX_TYPE_GST_ON_INCOME;
    invoiceArray.push(invoiceLine8);
  } else {
    const totalBillingsWithGST = provider.grossGst + provider.onlineScripts;

    // Second line has Total Billings
    const invoiceLine2 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine2.Description = `Total Billings: $${formatNumber(
      provider.paymentTotal + provider.onlineScripts
    )}. GST Free Billings: $${formatNumber(
      provider.paymentTotal - provider.grossGst
    )}. Billings with GST: $${formatNumber(totalBillingsWithGST)} ${
      totalBillingsWithGST !== 0
        ? `( ${
            provider.onlineScripts !== 0
              ? `Online Repeats - ${onlineScriptsPeriod}: $${formatNumber(
                  provider.onlineScripts
                )}. `
              : ''
          }${
            provider.grossGst !== 0
              ? `Medical Reports: $${formatNumber(provider.grossGst)}. `
              : ''
          })`
        : ''
    }`;
    invoiceArray.push(invoiceLine2);

    // Third line has procedure billings
    if (provider.procedureFees !== 0) {
      const invoiceLine3 = fillInvoiceDetails(
        provider,
        invoicePrefix + invoiceSuffix,
        periodEndStr,
        dueDateStr
      );
      invoiceLine3.Description = `Procedure Billings: $${formatNumber(
        provider.procedureFees
      )}`;
      invoiceArray.push(invoiceLine3);
    }

    // Fourth line has service fee
    const invoiceLine4 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine4.Description = `Service & Facilities Administration Fee (35% of Billings): $${formatNumber(
      provider.netServiceFee
    )} + GST`;
    invoiceArray.push(invoiceLine4);

    // Fifth line has net billings
    const invoiceLine5 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine5.Description = `Net Billings: $${formatNumber(
      provider.netBillings
    )}`;
    invoiceArray.push(invoiceLine5);

    // Sixth line is the actual fee line including quantity, amount, tax account etc
    const invoiceLine6 = fillInvoiceDetails(
      provider,
      invoicePrefix + invoiceSuffix,
      periodEndStr,
      dueDateStr
    );
    invoiceLine6.Description = 'Service & Facilities Administration Fee';
    invoiceLine6.Quantity = 1;
    invoiceLine6.UnitAmount = provider.netServiceFee;
    invoiceLine6.AccountCode = Constants.XERO_FEE_ACCOUNT_CODE;
    invoiceLine6.TaxType = Constants.XERO_FEE_TAX_TYPE_GST_ON_INCOME;
    invoiceArray.push(invoiceLine6);
  }

  return invoiceArray;
};

export default createInvoiceLinesForProvider;
