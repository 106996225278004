import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import Constants from '../../../../../constants';

const ViewButton = (props) => {
  const handleItemClick = () => {
    props.history.push(`${Constants.URLS.PAYDAY_HISTORY}/${props.paydayId}`);
  };

  return (
    <Icon
      link
      bordered
      color="blue"
      onClick={handleItemClick}
      name="chevron right"
    />
  );
};

export default withRouter(ViewButton);
