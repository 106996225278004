import _ from 'lodash';
import React from 'react';
import { Table } from 'semantic-ui-react';

const PaymentsWarnings = (props) => {
  const { warnings } = props;

  return (
    <Table color="red" celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Warning</Table.HeaderCell>
          <Table.HeaderCell>Provider</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Patient</Table.HeaderCell>
          <Table.HeaderCell>Invoice</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(_.uniq(warnings).sort(), (warning, index) => {
          return (
            <Table.Row key={`${warning}-${index}`}>
              <Table.Cell>{warning.warning}</Table.Cell>
              <Table.Cell>{warning.Provider}</Table.Cell>
              <Table.Cell>{warning.Description}</Table.Cell>
              <Table.Cell>{warning['Patient Name']}</Table.Cell>
              <Table.Cell>{warning['Invoice No.']}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default PaymentsWarnings;
