import { useContext, useEffect, useState } from 'react';

import loadDataV2 from '../../api/load-data-v2';
import Constants from '../../constants';
import { Context } from '../../store';

export default (initialPayload) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [payload, setPayload] = useState(initialPayload);

  const { dispatch } = useContext(Context);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await loadDataV2(payload);
        setData(response);
      } catch (error) {
        console.log(error);
        dispatch({
          type: Constants.ACTIONS.API_ERROR,
          payload: { status: error.response.status },
        });
      }

      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, payload]);

  return [{ isLoading, data }, setPayload];
};
