import _ from 'lodash';

import findMatchingProvider from '../../../../../../shared/payment-files/find-matching-provider';
import checkForPaymentWarnings from '../../components/provider-payments-list/utils/check-for-payment-warnings';

const checkCustomItems = (customFeeDescriptions, paymentData, providerList) => {
  // Capture any payments for custom items (ie with no item number).
  // These will be checked at the end of the run to see if they have
  // been billed correctly (ie to the practice or provider where appropriate)
  // or if the item description does not match any from the list of known custom items.
  if (
    paymentData['Item No.'].length === 0 &&
    _.toLower(paymentData.Status) !== 'reversed payment'
  ) {
    // Strip off time stamp for any second visit on same day descriptions
    let description = paymentData.Description;
    //const regex = new RegExp('^.*,\s\d\d:\d\d$');
    const result = /^.*,\s\d\d:\d\d$/.test(description);
    if (result) {
      description = description.slice(0, -7);
    }

    const provider = findMatchingProvider(providerList, paymentData);

    return checkForPaymentWarnings(
      customFeeDescriptions,
      {
        ...paymentData,
        Description: description,
      },
      provider
    );
  }
  return paymentData;
};

export default checkCustomItems;
