import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Container, Grid, Header, Image } from 'semantic-ui-react';

import logo from '../../assets/images/logo.png';

const FourOhFour = () => {
  const [goHome, setGoHome] = useState(false);

  const handleHome = () => {
    setGoHome(true);
  };

  if (goHome) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <Grid
        textAlign="center"
        style={{ paddingTop: '100px' }}
        verticalAlign="middle"
      >
        <Grid.Column width={8}>
          <Header as="h1" textAlign="center">
            <Image src={logo} /> 404 - Page Not Found
          </Header>
          <Button onClick={handleHome} color="teal" fluid size="large">
            Home
          </Button>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default FourOhFour;
