import _ from 'lodash';
import React, { useContext } from 'react';
import { Table } from 'semantic-ui-react';

import { Context } from '../../../../../../store';
import PaymentsWarnings from '../payments-warnings';
import ProviderPaymentsRow from '../provider-payments-row';

const ProviderPaymentsList = ({ customFeeDescriptions }) => {
  const { store } = useContext(Context);
  const warningItems = store.payday.paydayWarnings;

  return (
    <>
      {_.size(warningItems) > 0 && <PaymentsWarnings warnings={warningItems} />}
      <Table celled striped>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={6}>Provider</Table.HeaderCell>
            <Table.HeaderCell width={3}>Payment Total</Table.HeaderCell>
            <Table.HeaderCell width={3}>Held Total</Table.HeaderCell>
            <Table.HeaderCell width={3}>Payments with GST</Table.HeaderCell>
            <Table.HeaderCell width={3}>GST</Table.HeaderCell>
            <Table.HeaderCell width={1} />
          </Table.Row>
        </Table.Header>

        {store.payday.grandTotal !== 0 && (
          <Table.Body>
            {_.map(store.payday.providerList, (provider) => {
              return (
                <ProviderPaymentsRow key={provider.name} provider={provider} />
              );
            })}
          </Table.Body>
        )}
      </Table>
    </>
  );
};

export default ProviderPaymentsList;
