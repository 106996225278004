import _ from 'lodash';

import Constants from '../../../constants';
import getIndependentDoctors from '../../../shared/get-independent-doctors';
import { roundTo2DP } from '../../../utils';
import getGrossServiceFee from '../get-gross-service-fee';
import getNetBillings from '../get-net-billings';
import getNetServiceFee from '../get-net-service-fee';
import getRegistrarTotals from '../get-registrar-totals';

export default (draft) => {
  const independentDoctors = getIndependentDoctors(draft.payday.providerList);
  let serviceFeeTotal = 0;

  _.forEach(independentDoctors, (provider) => {
    if (_.isUndefined(provider.procedureFees)) {
      _.set(provider, 'procedureFees', 0);
    }
    _.set(provider, 'netServiceFee', getNetServiceFee(provider));
    _.set(provider, 'grossServiceFee', getGrossServiceFee(provider));
    _.set(provider, 'netBillings', getNetBillings(provider));
    serviceFeeTotal += provider.grossServiceFee;
  });

  const {
    registrarBilledTotal,
    registrarScriptTotal,
    registrarFeesWithGst,
  } = getRegistrarTotals(draft.payday.providerList);

  const practice = _.find(draft.payday.providerList, { isPractice: true });
  draft.payday.practiceChargesInclGST =
    practice.grossGst + registrarFeesWithGst;

  draft.payday.practiceChargesGSTFree =
    practice.paymentTotal -
    practice.grossGst -
    roundTo2DP(draft.payday.procedureFees * Constants.PROCEDURE_MULTIPLIER);

  let scriptTotal = 0;
  let procedureTotal = 0;

  _.forEach(draft.payday.providerList, (provider) => {
    scriptTotal += roundTo2DP(provider.onlineScripts);
    procedureTotal += roundTo2DP(provider.procedureFees);
  });

  const expectedFeesTotal = roundTo2DP(
    draft.payday.procedureFees * Constants.PROCEDURE_MULTIPLIER
  );

  draft.payday.procedureAdjustments = procedureTotal - expectedFeesTotal;
  draft.payday.onlineScriptsTotal = scriptTotal;
  draft.payday.registrarOnlineScriptsTotal = registrarScriptTotal;
  draft.payday.serviceFeeTotal = roundTo2DP(serviceFeeTotal);
  draft.payday.registrarFeeTotal = registrarBilledTotal - registrarFeesWithGst;
  draft.payday.registrarBillings = registrarBilledTotal;
  draft.payday.registrarFeesWithGst = registrarFeesWithGst;
  draft.payday.grandTotal = draft.payday.billingsTotal + scriptTotal;
};
