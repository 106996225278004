import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import Constants from '../../../../constants';

const Paginator = (props) => {
  const { next, prev } = props;

  const handlePrevClick = () => {
    props.history.push(`${Constants.URLS.PAYDAY_HISTORY}/${prev}`);
  };

  const handleNextClick = () => {
    props.history.push(`${Constants.URLS.PAYDAY_HISTORY}/${next}`);
  };

  return (
    <>
      <Button.Group>
        <Button
          basic
          color="blue"
          disabled={_.isNull(prev)}
          onClick={handlePrevClick}
          labelPosition="left"
          icon="left chevron"
          content="Prev"
        />
        <Button
          basic
          color="blue"
          disabled={_.isNull(next)}
          onClick={handleNextClick}
          labelPosition="right"
          icon="right chevron"
          content="Next"
        />
      </Button.Group>
    </>
  );
};

export default withRouter(Paginator);
