import React, { useContext } from 'react';

import { Context } from '../../store';
import ErrorModal from './error-modal';
import SideNav from './sidenav';
import styles from './styles.module.sass';

const Dashboard = (props) => {
  const { store } = useContext(Context);
  const { children } = props;

  return (
    <>
      <SideNav />
      <div className={styles.contentWrapper}>
        <div className={styles.contentWrapperInner}>
          {store.apiError && <ErrorModal />}
          {children}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
