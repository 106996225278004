import React from 'react';
import { Button } from 'semantic-ui-react';

function InvoiceExporterButton(props) {
  const { performExport, isExporting } = props;
  return (
    <>
      <Button
        basic
        fluid
        onClick={performExport}
        color="blue"
        content="Export Invoices"
        icon="download"
        labelPosition="left"
        disabled={isExporting}
        loading={isExporting}
      />
    </>
  );
}

export default InvoiceExporterButton;
