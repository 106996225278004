import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';

import Constants from '../../../../../constants';
import isArchivedProvider from '../../../../../shared/is-archived-provider';
import { Context } from '../../../../../store';
import ProviderInvoiceItem from '../provider-invoice-item';

const ProviderInvoiceList = ({
  payday,
  providerList,
  isDisplayOnly,
  providerGstLines,
}) => {
  const { dispatch } = useContext(Context);
  const [allowOnlineScriptEntry, setAllowOnlineScriptEntry] = useState(false);
  const [allowProcedureFeeEntry, setAllowProcedureFeeEntry] = useState(false);

  const doctors = _.reject(providerList, { isPractice: true });
  const activeDoctors = _.filter(doctors, (provider) => {
    return !isArchivedProvider({
      provider,
      comparisonDate: payday.billingStartDate,
    });
  });

  let onlineScriptButtonText = 'Enter';
  if (allowOnlineScriptEntry) {
    onlineScriptButtonText = 'Apply';
  }

  let procedureFeeButtonText = 'Enter';
  if (allowProcedureFeeEntry) {
    procedureFeeButtonText = 'Apply';
  }

  const applyOnlineScripts = () => {
    if (onlineScriptButtonText === 'Enter') {
      setAllowOnlineScriptEntry(true);
      dispatch({
        type: Constants.ACTIONS.SET_PAYDAY_STEP_NO,
        payload: { stepNo: 2 },
      });
    } else {
      setAllowOnlineScriptEntry(false);
      dispatch({
        type: Constants.ACTIONS.SET_PAYDAY_STEP_NO,
        payload: { stepNo: 3 },
      });
    }
  };

  const applyProcedureFeeAdjustment = () => {
    if (procedureFeeButtonText === 'Enter') {
      setAllowProcedureFeeEntry(true);
      dispatch({
        type: Constants.ACTIONS.SET_PAYDAY_STEP_NO,
        payload: { stepNo: 2 },
      });
    } else {
      setAllowProcedureFeeEntry(false);
      dispatch({
        type: Constants.ACTIONS.SET_PAYDAY_STEP_NO,
        payload: { stepNo: 3 },
      });
    }
  };

  return (
    <Table celled striped>
      <Table.Header>
        <Table.Row textAlign="center">
          <Table.HeaderCell width={4}>Provider</Table.HeaderCell>
          <Table.HeaderCell width={isDisplayOnly ? 2 : 3}>
            Online Scripts
            {!isDisplayOnly && (
              <>
                <br />
                <br />
                <Button
                  basic
                  size="mini"
                  onClick={applyOnlineScripts}
                  color="blue"
                  content={onlineScriptButtonText}
                  disabled={payday.serviceFeeTotal === 0 || payday.stepNo > 3}
                />
              </>
            )}
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>PMS Billings</Table.HeaderCell>
          <Table.HeaderCell width={3}>Held Billings</Table.HeaderCell>
          <Table.HeaderCell width={2}>Net Service Fee</Table.HeaderCell>
          <Table.HeaderCell width={2}>Gross Service Fee</Table.HeaderCell>
          <Table.HeaderCell width={2}>
            Procedures
            {!isDisplayOnly && (
              <>
                <br />
                <br />
                <Button
                  basic
                  size="mini"
                  onClick={applyProcedureFeeAdjustment}
                  color="blue"
                  content={procedureFeeButtonText}
                  disabled={payday.serviceFeeTotal === 0 || payday.stepNo > 3}
                />
              </>
            )}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>Net Billings</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      {payday.serviceFeeTotal !== 0 && (
        <Table.Body>
          {_.map(activeDoctors, (provider) => {
            return (
              <ProviderInvoiceItem
                key={provider.name}
                provider={provider}
                allowOnlineScriptEntry={allowOnlineScriptEntry}
                allowProcedureFeeEntry={allowProcedureFeeEntry}
                isDisplayOnly={isDisplayOnly}
                providerGstLines={providerGstLines}
              />
            );
          })}
        </Table.Body>
      )}
    </Table>
  );
};

export default ProviderInvoiceList;
