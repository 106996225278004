import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

import ReconciliationActions from '../../context';
import usePaymentsReconciliation from '../../use-payments-reconcilation';
import PaymentsImport from '../import/payments-import';
import PaymentList from '../payment-list';
import { MODE_RECONCILE } from '../reconciliation-modes';

const ActiveTabPane = () => {
  const searchPayload = { criteria: { reconciled: false } };
  const { actions, isLoading, data } = usePaymentsReconciliation(searchPayload);

  const { latestPaymentDate, providerData } = data;

  return (
    <ReconciliationActions.Provider value={actions}>
      <Grid celled="internally">
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4}>
            <PaymentsImport providerList={providerData} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3">{`Latest Payment Date: ${latestPaymentDate}`}</Header>
          </Grid.Column>
        </Grid.Row>
        <PaymentList data={data} isLoading={isLoading} mode={MODE_RECONCILE} />
      </Grid>
    </ReconciliationActions.Provider>
  );
};

export default ActiveTabPane;
