import _ from 'lodash';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Header, Icon } from 'semantic-ui-react';

import Loader from '../../components/loader';
import Constants from '../../constants';
import useApi from '../../api/use-api';
import { Context } from '../../store';
import FinalisePayday from './components/finalise-payday';
import InvoiceCalculator from './components/invoice-calculator';
import PaymentsUploader from './components/payments-uploader';

const Payday = () => {
  const { dispatch, store } = useContext(Context);

  const [{ isLoading, data }] = useApi({ type: Constants.LOAD.PAYDAY });

  if (store.payday.stepNo >= 4) {
    dispatch({ type: Constants.ACTIONS.RESET_PAYDAY });
  }

  return (
    <>
      <Header as="h1">
        <Icon name="money bill alternate outline" />
        <Header.Content>New Payday</Header.Content>
      </Header>
      {isLoading && <Loader />}
      {!isLoading && !_.isEmpty(data) && (
        <Grid celled="internally">
          {store.payday.stepNo >= 4 && (
            <Redirect
              to={`${Constants.URLS.PAYDAY_HISTORY}/${store.payday.paydayId}`}
            />
          )}
          {store.payday.stepNo >= 3 && <FinalisePayday />}
          {store.payday.stepNo >= 2 && <InvoiceCalculator />}
          {store.payday.stepNo >= 1 && <PaymentsUploader providerList={data} />}
        </Grid>
      )}
    </>
  );
};

export default Payday;
