import _ from 'lodash';

export default (paydayDetail) => {
  if (_.get(paydayDetail, 'grandTotal')) {
    const transformed = {};
    transformed.registrarFeeTotal =
      paydayDetail.registrarBillings - paydayDetail.registrarFeesWithGst;
    transformed.registrarOnlineScriptsTotal =
      paydayDetail.registrarOnlineScriptsTotal;
    transformed.serviceFeeTotal = paydayDetail.feeTotal;
    transformed.practiceChargesInclGST = paydayDetail.practiceChargesWithGst;
    transformed.practiceChargesGSTFree = paydayDetail.practiceChargesGstFree;
    transformed.onlineScriptsTotal = paydayDetail.onlineScripts;
    transformed.onlineScriptsPeriod = paydayDetail.onlineScriptsPeriod;
    transformed.grandTotal = paydayDetail.grandTotal;
    transformed.directBillingsTotal = paydayDetail.directBillingsTotal;
    transformed.providerList = paydayDetail.invoices;
    transformed.billingStartDate = paydayDetail.billingPeriodStart;
    transformed.billingEndDate = paydayDetail.billingPeriodEnd;
    transformed.gstItems = paydayDetail.gstItems;
    transformed.nextId = paydayDetail.nextId;
    transformed.prevId = paydayDetail.prevId;
    transformed.procedureFees = paydayDetail.procedureFees;
    transformed.procedureAdjustments = paydayDetail.procedureAdjustments;
    return transformed;
  }
  return {};
};
