/* eslint-disable sort-keys */
const Constants = {
  API_BASE: '/app',
  GST_MULTIPLIER: 1.1,
  PROCEDURE_MULTIPLIER: 0.8,
  SERVICE_FEE_PERCENTAGE: 0.35,
  XERO_FEE_ACCOUNT_CODE: '230',
  XERO_FEE_TAX_TYPE_GST_ON_INCOME: 'GST on Income',
  XERO_FEE_TAX_TYPE_BAS_EXCLUDED: 'BAS Excluded',
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_FORMAT_DDMMYY: 'DDMMYY',
  DATE_FORMAT_YEAR_FIRST: 'YYYY-MM-DD',
  DATE_FORMAT_COMPACT: 'YYYYMMDD',
  CHARTS: {
    FIN_YEAR_LIMIT: 10,
  },
  URLS: {
    ROOT: '/',
    DASHBOARD: '/db',
    PROVIDER_LIST: '/db/providers',
    PAYDAY: '/db/payday',
    PAYDAY_HISTORY: '/db/payday-history',
    RECONCILE_PAYMENTS: '/db/reconcile-payments',
  },
  LOAD: {
    PROVIDER_LIST: 'PROVIDER_LIST',
    PAYDAY: 'PAYDAY',
    PAYDAY_HISTORY: 'PAYDAY_HISTORY',
    PAYDAY_DETAIL: 'PAYDAY_DETAIL',
    PAYMENT_GROUPS: 'PAYMENT_GROUPS',
    PRACTICE_FEE_DESCRIPTIONS: 'PRACTICE_FEE_DESCRIPTIONS',
  },
  ACTIONS: {
    USER_LOGIN: 'USER_LOGIN',
    LOGOUT: 'LOGOUT',
    API_ERROR: 'API_ERROR',
    CLEAR_API_ERROR: 'CLEAR_API_ERROR',
    LOADING_PAYMENTS_COMPLETE: 'LOADING_PAYMENTS_COMPLETE',
    LOADING_PAYMENTS_START: 'LOADING_PAYMENTS_START',
    CALCULATE_INVOICES: 'CALCULATE_INVOICE',
    SET_PAYDAY_STEP_NO: 'SET_PAYDAY_STEP_NO',
    ALLOW_ONLINE_SCRIPT_ENTRY: 'ALLOW_ONLINE_SCRIPT_ENTRY',
    ADD_ONLINE_SCRIPT: 'ADD_ONLINE_SCRIPT',
    ADJUST_PROCEDURE_FEES: 'ADJUST_PROCEDURE_FEES',
    RESET_PAYDAY: 'RESET_PAYDAY',
    FINALISE_PAYDAY: 'FINALISE_PAYDAY',
  },
  BANKING: {
    HOLDING_ACCT_BSB: '083-170',
    HOLDING_ACCT_NO: '245606174',
    OPERATING_ACCT_BSB: '083-170',
    OPERATING_ACCT_NO: '243354786',
  },
};

export default Constants;
