import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';

const DateRangeSelector = ({
  handleOnClick,
  isLoading,
  startDate,
  endDate,
}) => {
  const [periodStartDate, setPeriodStartDate] = useState(startDate);
  const [periodEndDate, setPeriodEndDate] = useState(endDate);

  const onClick = () => {
    handleOnClick(periodStartDate, periodEndDate);
  };

  return (
    <Grid.Row verticalAlign="bottom">
      <Grid.Column width={3}>
        <Header sub>Date From</Header>
        <DatePicker
          selected={periodStartDate}
          onChange={(date) => setPeriodStartDate(date)}
          selectsStart
          startDate={periodStartDate}
          endDate={periodEndDate}
          dateFormat="dd/MM/yyyy"
          disabled={isLoading}
          readOnly={isLoading}
          className="semantic-input"
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <Header sub>Date To</Header>
        <DatePicker
          selected={periodEndDate}
          onChange={(date) => setPeriodEndDate(date)}
          selectsEnd
          startDate={periodStartDate}
          endDate={periodEndDate}
          dateFormat="dd/MM/yyyy"
          disabled={isLoading}
          readOnly={isLoading}
          className="semantic-input"
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={onClick}
          basic
          icon
          color="orange"
          labelPosition="left"
        >
          <Icon name="search" />
          Search
        </Button>
      </Grid.Column>
    </Grid.Row>
  );
};

export default DateRangeSelector;
