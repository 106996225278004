import _ from 'lodash';
import Papa from 'papaparse';
import React, { useContext, useState } from 'react';
import { Grid } from 'semantic-ui-react';

import useApi from '../../../../api/use-api';
import Loader from '../../../../components/loader';
import Constants from '../../../../constants';
import { Context } from '../../../../store';
import ProviderPaymentsList from './components/provider-payments-list';
import UploaderButton from './components/uploader-button';
import UploaderStats from './components/uploader-stats';
import processPaymentFile from './utils/process-payment-file';

const PaymentsUploader = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { store, dispatch } = useContext(Context);
  const fileInputRef = React.createRef();

  const [
    { isLoading: isLoadingCustomFeeItems, data: customFeeDescriptions },
  ] = useApi({
    type: Constants.LOAD.PRACTICE_FEE_DESCRIPTIONS,
  });

  const handleLoadComplete = (results) => {
    const paydayTotals = processPaymentFile(
      props.providerList,
      results.data,
      customFeeDescriptions
    );

    setIsLoading(false);
    const action = {
      type: Constants.ACTIONS.LOADING_PAYMENTS_COMPLETE,
      payload: _.cloneDeep(paydayTotals),
    };
    dispatch(action);
  };

  const handleFileChosen = (event) => {
    setIsLoading(true);
    dispatch({ type: Constants.ACTIONS.LOADING_PAYMENTS_START });

    const file = event.target.files[0];

    Papa.parse(file, {
      complete: handleLoadComplete,
      header: true,
      transformHeader: (h) => {
        return _.replace(h, '\n($ incl GST)', '');
      },
    });
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const disabled = store.payday.stepNo > 1;

  return (
    <>
      {isLoadingCustomFeeItems && <Loader />}
      {!isLoadingCustomFeeItems && (
        <Grid.Row>
          <Grid.Column width={4}>
            <UploaderButton
              handleFileChosen={handleFileChosen}
              handleFileSelect={handleFileSelect}
              fileInputRef={fileInputRef}
              isLoading={isLoading}
              disabled={disabled}
            />
            <UploaderStats />
          </Grid.Column>
          <Grid.Column width={12}>
            <ProviderPaymentsList
              customFeeDescriptions={customFeeDescriptions}
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export default PaymentsUploader;
