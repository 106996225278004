import React from 'react';
import { Header, Icon, Tab } from 'semantic-ui-react';

import ActiveTabPane from './components/active-tab-pane';
import ReconciledTabPane from './components/reconciled-tab-pane';

const panes = [
  {
    menuItem: 'Active Payments',
    render: () => (
      <Tab.Pane>
        <ActiveTabPane />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Reconciled',
    render: () => (
      <Tab.Pane>
        <ReconciledTabPane />
      </Tab.Pane>
    ),
  },
];

const ReconcilePayments = () => {
  return (
    <>
      <Header as="h1">
        <Icon name="calendar check outline" />
        <Header.Content>Reconcile Payments</Header.Content>
      </Header>
      <Tab panes={panes} />
    </>
  );
};

export default ReconcilePayments;
