import _ from 'lodash';

export const formatNumber = (number) => {
  const newNumber = number.toFixed(2);
  return _.replace(newNumber, /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const roundTo2DP = (number) => {
  return Math.round(number * 100) / 100;
};

export const getJWTHeader = () => {
  const token = sessionStorage.getItem('ss-token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return config;
};
