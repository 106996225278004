import { isNull } from 'lodash/fp';
import moment from 'moment';

import Constants from '../../constants';

const isArchivedProvider = ({ provider, comparisonDate = moment() }) => {
  if (isNull(provider.archivedDate)) {
    return false;
  }

  const archivedDate = moment(
    provider.archivedDate,
    Constants.DATE_FORMAT_YEAR_FIRST
  );

  return archivedDate.isBefore(
    moment(comparisonDate, Constants.DATE_FORMAT_YEAR_FIRST)
  );
};

export default isArchivedProvider;
