import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';

import { login } from '../../api/user';
import logo from '../../assets/images/logo.png';
import Constants from '../../constants';
import { Context } from '../../store';

const useLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { dispatch } = useContext(Context);

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await login({ username, password });
    setIsLoading(false);
    if (response.ok) {
      dispatch({ type: Constants.ACTIONS.USER_LOGIN });
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(_.trim(_.get(event.target, 'value', '')));
  };

  const handlePasswordChange = (event) => {
    setPassword(_.trim(_.get(event.target, 'value', '')));
  };

  return {
    errorMessage,
    handlePasswordChange,
    handleSubmit,
    handleUsernameChange,
    isLoading,
    password,
    username,
  };
};

const Login = () => {
  const {
    errorMessage,
    handlePasswordChange,
    handleSubmit,
    handleUsernameChange,
    isLoading,
    password,
    username,
  } = useLogin();
  const { store } = useContext(Context);

  if (store.isAuthenticated) {
    return <Redirect to="/db" />;
  }
  return (
    <Container>
      <Grid
        textAlign="center"
        style={{ paddingTop: '100px' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            <Image src={logo} />
            Log-in to your account
          </Header>
          <Form onSubmit={handleSubmit} size="large">
            <Segment>
              <Form.Input
                value={username}
                onChange={handleUsernameChange}
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Username"
              />
              <Form.Input
                value={password}
                onChange={handlePasswordChange}
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
              />
              <Button color="blue" loading={isLoading} fluid size="large">
                Login
              </Button>
            </Segment>
          </Form>
          {errorMessage && (
            <Message data-testid="error-message" negative>
              <p>{errorMessage}</p>
            </Message>
          )}
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default Login;
