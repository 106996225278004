import _ from 'lodash';
import moment from 'moment';

import Constants from '../../../../../constants';

const createPaymentGroup = ({
  providerId = '',
  invoiceNo = '',
  groupDate,
  paymentId = '',
  total,
  gst = 0,
  type,
}) => {
  return {
    providerId,
    groupDate: moment(groupDate, Constants.DATE_FORMAT).format(
      Constants.DATE_FORMAT_YEAR_FIRST
    ),
    paymentId,
    total,
    gst,
    type,
    invoiceNo,
  };
};

const preparePaymentGroups = (importTotals) => {
  const paymentGroups = [];
  _.forEach(importTotals.providerList, (provider) => {
    _.forOwn(_.get(provider, 'medicareTotals'), (totalsById, groupDate) => {
      _.forOwn(totalsById, (total, paymentId) => {
        const paymentGroup = createPaymentGroup({
          providerId: provider.id,
          groupDate,
          paymentId,
          total,
          type: 'Medicare',
        });
        paymentGroups.push(paymentGroup);
      });
    });

    _.forOwn(_.get(provider, 'dvaTotals'), (totalsById, groupDate) => {
      _.forOwn(totalsById, (total, paymentId) => {
        const paymentGroup = createPaymentGroup({
          providerId: provider.id,
          groupDate,
          paymentId,
          total,
          type: 'DVA',
        });
        paymentGroups.push(paymentGroup);
      });
    });
  });

  _.forEach(_.get(importTotals, 'depositAllocations'), (deposit) => {
    const {
      amount,
      gst,
      invoiceNo,
      paymentMethod,
      transactionDate,
      providerId,
    } = deposit;
    const paymentGroup = createPaymentGroup({
      groupDate: transactionDate,
      total: amount,
      invoiceNo,
      gst,
      type: paymentMethod,
      providerId,
    });
    paymentGroups.push(paymentGroup);
  });

  _.forOwn(_.get(importTotals, 'cashTotals'), (cashTotal, groupDate) => {
    const paymentGroup = createPaymentGroup({
      groupDate,
      total: cashTotal.amount,
      gst: cashTotal.gst,
      type: 'Cash',
    });
    paymentGroups.push(paymentGroup);
  });

  _.forOwn(_.get(importTotals, 'chequeTotals'), (chequeTotal, groupDate) => {
    const paymentGroup = createPaymentGroup({
      groupDate,
      total: chequeTotal.amount,
      gst: chequeTotal.gst,
      type: 'Cheque',
    });
    paymentGroups.push(paymentGroup);
  });

  _.forOwn(_.get(importTotals, 'eftTotals'), (amount, groupDate) => {
    const paymentGroup = createPaymentGroup({
      groupDate,
      total: amount,
      gst: 0,
      type: 'EFT',
    });
    paymentGroups.push(paymentGroup);
  });

  return paymentGroups;
};

export default preparePaymentGroups;
